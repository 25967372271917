<template>
<svg version="1.1" id="logo-fitnessemotion" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
x="0px" y="0px" viewBox="0 0 293.2 94.1" style="enable-background:new 0 0 293.2 94.1;" xml:space="preserve">
<path class="st0" d="M45.5,51.2l-12.8,3.1c-3.1,0.7-5.1,1.1-6.1,1.3c-2.4,0.4-5.5,0.5-9.3,0.5s-5.5-0.5-7.2-1.6
c-0.9-0.5-1.9-1.7-3.2-3.4c-1,0.5-2,0.9-2.8,1.3c-0.9,0.4-1.8,0.7-2.7,1c-0.6,0.2-1,0.1-1.2-0.4s-0.2-1.1,0-1.8s0.5-0.8,0.8-0.9
c0.7-0.2,1.5-0.5,2.3-0.8C5.6,48.5,6.9,48,7,48c-0.1-1,0-2,0.2-3.2c0.3-1.2,0.6-2.5,1.1-3.8c1.1-3.1,3.2-5.7,6.1-7.9
c2.9-2.1,5.5-3.2,7.9-3.2s3.4,0.6,4.4,1.9c1.1,1.5,1.2,3.5,0.3,6.1s-3.1,4.4-7.2,6.9c-1.7,1.1-4.4,2.6-8,4.6
c0.8,1.1,1.7,1.9,2.7,2.3c1.4,0.7,3,1.1,4.9,1.1c3.8,0,6.4,0,7.7-0.2c1.4-0.1,3.7-0.7,7-1.5c3.3-0.9,5.5-1.5,6.7-1.8
c1.9-0.5,3.4-0.8,4.5-1c1-0.2,1.8-0.1,2.2,0.2s0.6,0.7,0.4,1.2s-1,1.2-2.2,1.5L45.5,51.2z M22.8,37.5c0.3-1.2,0.3-2.2,0-2.9
s-1.1-1.1-2.4-1.1s-3.3,0.7-4.9,2.1s-2.8,3-3.4,4.8s-0.8,2.1-1.1,3.1s-0.4,1.9-0.2,2.8c3.3-1.6,6-3.1,8.1-4.6S22.3,38.8,22.8,37.5z"
/>
<path class="st0" d="M74,41.2c-2,0.8-4,2.8-6.6,5c-3.8,3.2-6.2,5.2-7.3,5.9c-2.6,1.8-5.1,2.7-7.4,2.7s-4-2.2-4.3-6.8
c-0.3-4,0.2-7.8,1.6-11.6l0.6-1.5c0.4-1.1,0.9-2,1.4-2.7s1.2-1,2.1-1s1.2,0.6,1.1,1.8c0,1.2-0.3,2.5-0.9,4c-1,2.9-1.5,5.7-1.5,8.6
s0.7,4.2,2.1,4.2s3.6-0.8,5.6-2.3c0.4-0.3,2.3-2,5.7-5c2.7-2.4,5-4.1,7.1-5.2c2.9-1.6,5.9-2.3,9-2.3s3.4,0.8,4.7,2.3
c1,1.1,2,2.9,3,5.2c0.7,1.8,1.5,3.5,2.2,5.2c0.8,1.7,1.5,2.5,2.3,2.5s1.7-0.4,3.2-1.3c1.9-1.2,3.4-2.1,4.4-2.7
c1.5-0.9,2.8-1.6,3.8-2.1c1.4-0.7,2.8-1,4-1c2.2,0,4,1.1,5.6,3.4c1.5,2.1,2,4,1.6,5.6c0,0.2-0.1,0.6-0.3,1.2c-0.4,1-1.1,1.5-2.2,1.3
c-0.5,0-0.9-0.6-1.2-1.5c-0.4-1-0.7-1.9-1.1-3c-0.5-0.9-1-1.6-1.7-2.2s-1.4-0.9-2.2-0.9s-2.1,0.4-3.5,1.2c-0.4,0.2-1.8,1.2-4.3,2.8
c-2.1,1.4-3.5,2.3-4.3,2.8c-1.7,0.9-3.2,1.4-4.5,1.4s-2.9-1.5-4.2-4.6c-0.3-0.8-1-2.5-2-5.1c-0.8-2-1.6-3.5-2.3-4.5
c-1-1.3-1.8-1.3-4.8-1.1"/>
<path class="st0" d="M157.5,45.8c-1,2.8-3.2,5-6.5,6.7s-7,2.5-10.9,2.5s-9.7-1.3-13.1-4s-4.3-6-2.9-10s2.1-3.3,4.4-4.9
c2.3-1.6,4.1-2.3,5.4-2.3c0.7,0,1.3,0,1.7,0c1,0,1.7,0.1,2.2,0.4c0.5,0.2,0.6,0.7,0.4,1.4s-0.9,1.3-1.8,1.5
c-0.9,0.2-1.8,0.3-2.9,0.3s-2,0.4-3.1,1.2s-1.8,1.6-2.1,2.4c-0.7,2,0,4.1,2.1,6.4c2.5,2.7,6.2,4.1,11,4.1s5.5-0.5,7.7-1.6
s3.5-2.3,4.1-3.8c0.4-0.8-0.2-1.9-1.7-3.2c-1.2-1.1-2.9-2.2-5.1-3.4c-1.7-0.6-2.9-1-3.6-1.2c-1.2-0.4-1.7-1-1.4-1.9
c0.2-0.8,0.6-1.3,1-1.5c0.5-0.2,1.3-0.3,2.5-0.3s1.8,0.4,3.6,1.2s3.3,1.7,4.7,2.7c2,1.4,3.2,2.5,3.8,3.3c0.9,1.3,1,2.7,0.4,4.2
L157.5,45.8z"/>
<path class="st0" d="M195.5,34.1c-0.3,0.8-0.7,1.3-1.3,1.4s-1.2,0.2-2,0.2s-1.7-0.1-3.1-0.4c-3.6-0.6-5.6-0.9-6-0.9s-1.7,0.3-4,0.8
c-1.3,0.3-2.2,0.4-2.8,0.4c-0.5,2.8-0.8,5.9-0.9,9.3c-0.1,3.5,0,5.6,0.6,6.3c0.2,0.4,0.8,0.8,1.8,1.3s1.4,1,1.2,1.4
c-0.3,0.8-0.8,1.4-1.5,1.7s-1.8,0.4-3.3,0.4c-2.2,0-3.2-3.4-3.1-10.3c0-2.9,0.3-5.7,0.7-8.4c-1,0.2-2.8,1.1-5.5,2.6
c-1.2,0.7-2,1-2.3,1c-1.1,0-1.4-0.5-1.1-1.4s1.7-2.5,3.8-3.9c1.8-1,3.5-2.1,5.4-3.1c0.5-2.6,1.1-5.7,2.1-9.3c0.9-3.6,2-7.1,3.3-10.6
s0.8-2.2,1.2-3.3s0.8-2.2,1.2-3.4c0.7-1.9,1.3-3.4,1.9-4.4c0.6-1,1.2-1.5,1.8-1.5c1.1,0,1.3,1.3,0.7,3.8s-1.8,6.1-3.4,10.6
s-1.2,3.8-1.9,6.9s-1.4,6.4-2,9.8c0.7,0,1.9-0.1,3.5-0.4s2.8-0.5,3.5-0.5c3.2,0,6,0.4,8.4,1.2s3.4,1.6,3.1,2.4L195.5,34.1z"/>
<path class="st0" d="M203.4,20.8c0,0.2-0.3,0.3-0.8,0.4c-0.5,0-0.8,0-0.9,0c-0.9,0.1-1.9-0.4-2.7-1.5c-0.9-1.1-1.1-2.2-0.6-3.1
c0.2-0.4,0.5-0.7,0.8-0.9s0.7-0.4,1.1-0.5c0.9-0.1,1.8,0.8,2.7,2.8C203.5,19.1,203.6,20.1,203.4,20.8
C203.3,20.8,203.4,20.8,203.4,20.8z M198.9,51.9c-0.3,1-0.7,1.6-1.1,1.9s-1,0.5-1.7,0.5c-1.3,0-2-1.4-2.2-4.1s0.2-5.3,1-7.6
s0.5-1.5,0.9-2.4c0.4-1,0.9-1.5,1.7-1.7c0.6-0.1,1.1,0.1,1.2,0.7c0.2,0.6,0.1,1.6-0.2,3.2c-0.3,1.6-0.3,3.5,0,5.8s0.4,3.6,0.4,3.8
L198.9,51.9L198.9,51.9z"/>
<path class="st0" d="M240.1,45.8c-1,2.8-3.2,5-6.5,6.7s-7,2.5-10.9,2.5s-9.7-1.3-13.1-4s-4.3-6-2.9-10s2.1-3.3,4.4-4.9
s4.1-2.3,5.4-2.3c0.7,0,1.3,0,1.7,0c1,0,1.7,0.1,2.2,0.4c0.5,0.2,0.6,0.7,0.4,1.4s-0.9,1.3-1.8,1.5s-1.8,0.3-2.9,0.3s-2,0.4-3.1,1.2
s-1.8,1.6-2.1,2.4c-0.7,2,0,4.1,2.1,6.4c2.5,2.7,6.2,4.1,11,4.1s5.5-0.5,7.7-1.6s3.5-2.3,4.1-3.8c0.4-0.8-0.2-1.9-1.7-3.2
c-1.2-1.1-2.9-2.2-5.1-3.4c-1.7-0.6-2.9-1-3.6-1.2c-1.2-0.4-1.7-1-1.4-1.9c0.2-0.8,0.6-1.3,1-1.5c0.5-0.2,1.3-0.3,2.5-0.3
s1.8,0.4,3.6,1.2s3.3,1.7,4.7,2.7c2,1.4,3.2,2.5,3.8,3.3c0.9,1.3,1,2.7,0.4,4.2L240.1,45.8z"/>
<path class="st0" d="M288.6,53.2c-0.5,1.3-2.5,1.9-6.2,1.9s-3.5-0.8-4.5-2.3c-0.5-0.8-1-1.6-1.5-2.3c-0.3-0.4-1-1.6-2.1-3.5
c-0.6-1.1-1.2-1.6-1.8-1.6c-0.9,0-2.5,0.6-4.8,1.8c-1,0.5-2.3,1.3-3.8,2.2c-2.1,1.3-4.1,2.5-6.1,3.8c-2.9,1.6-5.3,2.4-7.3,2.4
c-2.9,0-4.7-2.2-5.3-6.5c-0.5-3.8-0.1-7.6,1.2-11.2s0.5-0.9,1.1-1.4s1.2-0.8,1.8-0.9c0.5,0,0.9,0.1,1.1,0.5s0.2,1.4-0.3,3.2
c-0.4,1.7-0.6,3.9-0.4,6.7c0.2,3.5,1.1,5.3,2.7,5.3s1.9-0.3,3.3-1c0,0,2.3-1.3,6.5-3.9c3.7-2.2,6-3.5,6.8-4c2.5-1.3,4.2-2,5.3-2
s2.3,1.2,3.6,3.7c0.8,1.6,1.5,2.9,2.3,3.7c0.8,1.3,1.4,2.2,1.8,2.7c0.8,1,1.5,1.5,2.1,1.5h1.6c1,0,1.8,0,2.3,0.2
S288.8,52.7,288.6,53.2L288.6,53.2z"/>
<path class="st0" d="M45.5,51.2l-12.8,3.1c-3.1,0.7-5.1,1.1-6.1,1.3c-2.4,0.4-5.5,0.5-9.3,0.5s-5.5-0.5-7.2-1.6
c-0.9-0.5-1.9-1.7-3.2-3.4c-1,0.5-2,0.9-2.8,1.3c-0.9,0.4-1.8,0.7-2.7,1c-0.6,0.2-1,0.1-1.2-0.4s-0.2-1.1,0-1.8s0.5-0.8,0.8-0.9
c0.7-0.2,1.5-0.5,2.3-0.8C5.6,48.5,6.9,48,7,48c-0.1-1,0-2,0.2-3.2c0.3-1.2,0.6-2.5,1.1-3.8c1.1-3.1,3.2-5.7,6.1-7.9
c2.9-2.1,5.5-3.2,7.9-3.2s3.4,0.6,4.4,1.9c1.1,1.5,1.2,3.5,0.3,6.1s-3.1,4.4-7.2,6.9c-1.7,1.1-4.4,2.6-8,4.6
c0.8,1.1,1.7,1.9,2.7,2.3c1.4,0.7,3,1.1,4.9,1.1c3.8,0,6.4,0,7.7-0.2c1.4-0.1,3.7-0.7,7-1.5c3.3-0.9,5.5-1.5,6.7-1.8
c1.9-0.5,3.4-0.8,4.5-1c1-0.2,1.8-0.1,2.2,0.2s0.6,0.7,0.4,1.2s-1,1.2-2.2,1.5L45.5,51.2z M22.8,37.5c0.3-1.2,0.3-2.2,0-2.9
s-1.1-1.1-2.4-1.1s-3.3,0.7-4.9,2.1s-2.8,3-3.4,4.8s-0.8,2.1-1.1,3.1s-0.4,1.9-0.2,2.8c3.3-1.6,6-3.1,8.1-4.6S22.3,38.8,22.8,37.5z"
/>
<path class="st0" d="M74,41.2c-2,0.8-4,2.8-6.6,5c-3.8,3.2-6.2,5.2-7.3,5.9c-2.6,1.8-5.1,2.7-7.4,2.7s-4-2.2-4.3-6.8
c-0.3-4,0.2-7.8,1.6-11.6l0.6-1.5c0.4-1.1,0.9-2,1.4-2.7s1.2-1,2.1-1s1.2,0.6,1.1,1.8c0,1.2-0.3,2.5-0.9,4c-1,2.9-1.5,5.7-1.5,8.6
s0.7,4.2,2.1,4.2s3.6-0.8,5.6-2.3c0.4-0.3,2.3-2,5.7-5c2.7-2.4,5-4.1,7.1-5.2c2.9-1.6,5.9-2.3,9-2.3s3.4,0.8,4.7,2.3
c1,1.1,2,2.9,3,5.2c0.7,1.8,1.5,3.5,2.2,5.2c0.8,1.7,1.5,2.5,2.3,2.5s1.7-0.4,3.2-1.3c1.9-1.2,3.4-2.1,4.4-2.7
c1.5-0.9,2.8-1.6,3.8-2.1c1.4-0.7,2.8-1,4-1c2.2,0,4,1.1,5.6,3.4c1.5,2.1,2,4,1.6,5.6c0,0.2-0.1,0.6-0.3,1.2c-0.4,1-1.1,1.5-2.2,1.3
c-0.5,0-0.9-0.6-1.2-1.5c-0.4-1-0.7-1.9-1.1-3c-0.5-0.9-1-1.6-1.7-2.2s-1.4-0.9-2.2-0.9s-2.1,0.4-3.5,1.2c-0.4,0.2-1.8,1.2-4.3,2.8
c-2.1,1.4-3.5,2.3-4.3,2.8c-1.7,0.9-3.2,1.4-4.5,1.4s-2.9-1.5-4.2-4.6c-0.3-0.8-1-2.5-2-5.1c-0.8-2-1.6-3.5-2.3-4.5
c-1-1.3-1.8-1.3-4.8-1.1"/>
<path class="st0" d="M157.5,45.8c-1,2.8-3.2,5-6.5,6.7s-7,2.5-10.9,2.5s-9.7-1.3-13.1-4s-4.3-6-2.9-10s2.1-3.3,4.4-4.9
c2.3-1.6,4.1-2.3,5.4-2.3c0.7,0,1.3,0,1.7,0c1,0,1.7,0.1,2.2,0.4c0.5,0.2,0.6,0.7,0.4,1.4s-0.9,1.3-1.8,1.5
c-0.9,0.2-1.8,0.3-2.9,0.3s-2,0.4-3.1,1.2s-1.8,1.6-2.1,2.4c-0.7,2,0,4.1,2.1,6.4c2.5,2.7,6.2,4.1,11,4.1s5.5-0.5,7.7-1.6
s3.5-2.3,4.1-3.8c0.4-0.8-0.2-1.9-1.7-3.2c-1.2-1.1-2.9-2.2-5.1-3.4c-1.7-0.6-2.9-1-3.6-1.2c-1.2-0.4-1.7-1-1.4-1.9
c0.2-0.8,0.6-1.3,1-1.5c0.5-0.2,1.3-0.3,2.5-0.3s1.8,0.4,3.6,1.2s3.3,1.7,4.7,2.7c2,1.4,3.2,2.5,3.8,3.3c0.9,1.3,1,2.7,0.4,4.2
L157.5,45.8z"/>
<path class="st0" d="M195.5,34.1c-0.3,0.8-0.7,1.3-1.3,1.4s-1.2,0.2-2,0.2s-1.7-0.1-3.1-0.4c-3.6-0.6-5.6-0.9-6-0.9s-1.7,0.3-4,0.8
c-1.3,0.3-2.2,0.4-2.8,0.4c-0.5,2.8-0.8,5.9-0.9,9.3c-0.1,3.5,0,5.6,0.6,6.3c0.2,0.4,0.8,0.8,1.8,1.3s1.4,1,1.2,1.4
c-0.3,0.8-0.8,1.4-1.5,1.7s-1.8,0.4-3.3,0.4c-2.2,0-3.2-3.4-3.1-10.3c0-2.9,0.3-5.7,0.7-8.4c-1,0.2-2.8,1.1-5.5,2.6
c-1.2,0.7-2,1-2.3,1c-1.1,0-1.4-0.5-1.1-1.4s1.7-2.5,3.8-3.9c1.8-1,3.5-2.1,5.4-3.1c0.5-2.6,1.1-5.7,2.1-9.3c0.9-3.6,2-7.1,3.3-10.6
s0.8-2.2,1.2-3.3s0.8-2.2,1.2-3.4c0.7-1.9,1.3-3.4,1.9-4.4c0.6-1,1.2-1.5,1.8-1.5c1.1,0,1.3,1.3,0.7,3.8s-1.8,6.1-3.4,10.6
s-1.2,3.8-1.9,6.9s-1.4,6.4-2,9.8c0.7,0,1.9-0.1,3.5-0.4s2.8-0.5,3.5-0.5c3.2,0,6,0.4,8.4,1.2s3.4,1.6,3.1,2.4L195.5,34.1z"/>
<path class="st0" d="M203.4,20.8c0,0.2-0.3,0.3-0.8,0.4c-0.5,0-0.8,0-0.9,0c-0.9,0.1-1.9-0.4-2.7-1.5c-0.9-1.1-1.1-2.2-0.6-3.1
c0.2-0.4,0.5-0.7,0.8-0.9s0.7-0.4,1.1-0.5c0.9-0.1,1.8,0.8,2.7,2.8C203.5,19.1,203.6,20.1,203.4,20.8
C203.3,20.8,203.4,20.8,203.4,20.8z M198.9,51.9c-0.3,1-0.7,1.6-1.1,1.9s-1,0.5-1.7,0.5c-1.3,0-2-1.4-2.2-4.1s0.2-5.3,1-7.6
s0.5-1.5,0.9-2.4c0.4-1,0.9-1.5,1.7-1.7c0.6-0.1,1.1,0.1,1.2,0.7c0.2,0.6,0.1,1.6-0.2,3.2c-0.3,1.6-0.3,3.5,0,5.8s0.4,3.6,0.4,3.8
L198.9,51.9L198.9,51.9z"/>
<path class="st0" d="M240.1,45.8c-1,2.8-3.2,5-6.5,6.7s-7,2.5-10.9,2.5s-9.7-1.3-13.1-4s-4.3-6-2.9-10s2.1-3.3,4.4-4.9
s4.1-2.3,5.4-2.3c0.7,0,1.3,0,1.7,0c1,0,1.7,0.1,2.2,0.4c0.5,0.2,0.6,0.7,0.4,1.4s-0.9,1.3-1.8,1.5s-1.8,0.3-2.9,0.3s-2,0.4-3.1,1.2
s-1.8,1.6-2.1,2.4c-0.7,2,0,4.1,2.1,6.4c2.5,2.7,6.2,4.1,11,4.1s5.5-0.5,7.7-1.6s3.5-2.3,4.1-3.8c0.4-0.8-0.2-1.9-1.7-3.2
c-1.2-1.1-2.9-2.2-5.1-3.4c-1.7-0.6-2.9-1-3.6-1.2c-1.2-0.4-1.7-1-1.4-1.9c0.2-0.8,0.6-1.3,1-1.5c0.5-0.2,1.3-0.3,2.5-0.3
s1.8,0.4,3.6,1.2s3.3,1.7,4.7,2.7c2,1.4,3.2,2.5,3.8,3.3c0.9,1.3,1,2.7,0.4,4.2L240.1,45.8z"/>
<path class="st0" d="M288.6,53.2c-0.5,1.3-2.5,1.9-6.2,1.9s-3.5-0.8-4.5-2.3c-0.5-0.8-1-1.6-1.5-2.3c-0.3-0.4-1-1.6-2.1-3.5
c-0.6-1.1-1.2-1.6-1.8-1.6c-0.9,0-2.5,0.6-4.8,1.8c-1,0.5-2.3,1.3-3.8,2.2c-2.1,1.3-4.1,2.5-6.1,3.8c-2.9,1.6-5.3,2.4-7.3,2.4
c-2.9,0-4.7-2.2-5.3-6.5c-0.5-3.8-0.1-7.6,1.2-11.2s0.5-0.9,1.1-1.4s1.2-0.8,1.8-0.9c0.5,0,0.9,0.1,1.1,0.5s0.2,1.4-0.3,3.2
c-0.4,1.7-0.6,3.9-0.4,6.7c0.2,3.5,1.1,5.3,2.7,5.3s1.9-0.3,3.3-1c0,0,2.3-1.3,6.5-3.9c3.7-2.2,6-3.5,6.8-4c2.5-1.3,4.2-2,5.3-2
s2.3,1.2,3.6,3.7c0.8,1.6,1.5,2.9,2.3,3.7c0.8,1.3,1.4,2.2,1.8,2.7c0.8,1,1.5,1.5,2.1,1.5h1.6c1,0,1.8,0,2.3,0.2
S288.8,52.7,288.6,53.2L288.6,53.2z"/>
<path class="st1" d="M221.8,75.6c-2.1,0-3.3,2-3.3,4.6s1.2,4.5,3.3,4.5s3.2-2,3.2-4.6S223.8,75.6,221.8,75.6L221.8,75.6z"/>
<path class="st1" d="M99.7,75.7c-0.7,0-1.1,0-1.4,0.1v8.8c0.3,0,0.7,0,1.1,0c2.8,0,4.6-1.5,4.6-4.7C104,77.1,102.4,75.6,99.7,75.7
L99.7,75.7z"/>
<path class="st1" d="M81.7,67.5v26.6h211.6V67.5H81.7z M104.8,85.3c-1.4,1.2-3.6,1.7-6.2,1.7s-2.7,0-3.5-0.2V73.6
c1.1-0.2,2.6-0.3,4.1-0.3c2.6,0,4.2,0.5,5.5,1.4c1.4,1,2.3,2.7,2.3,5.1C107.1,82.2,106.2,84.2,104.8,85.3L104.8,85.3z M112.5,86.9
h-3.1V73.4h3.1V86.9z M124,86.9h-8.6V73.4h8.3v2.5h-5.3v2.8h5v2.5h-5v3.2h5.5L124,86.9L124,86.9z M138.9,75.9h-5.2V79h4.8v2.5h-4.8
v5.4h-3.1V73.4h8.2v2.5H138.9z M144.6,86.9h-3.1V73.4h3.1V86.9z M156.9,76h-3.7v10.9h-3.1V76h-3.6v-2.6h10.4L156.9,76L156.9,76z
M170,86.9h-3.2l-2.9-5.2c-0.8-1.4-1.7-3.2-2.3-4.8l0,0c0,1.8,0.1,3.7,0.1,5.9v4.1h-2.8V73.4h3.6l2.8,4.9c0.8,1.4,1.6,3.1,2.2,4.6
l0,0c-0.2-1.8-0.3-3.6-0.3-5.6v-3.9h2.8L170,86.9L170,86.9z M181.5,86.9h-8.6V73.4h8.3v2.5h-5.3v2.8h5v2.5h-5v3.2h5.5v2.5H181.5z
M187.2,87.1c-1.5,0-3.1-0.4-3.8-0.8l0.6-2.5c0.8,0.4,2.1,0.8,3.4,0.8s2.1-0.6,2.1-1.5s-0.6-1.3-2.3-1.9c-2.2-0.8-3.7-2-3.7-4
s1.9-4.1,5.1-4.1s2.6,0.3,3.4,0.7l-0.7,2.5c-0.5-0.3-1.5-0.6-2.8-0.6s-2,0.6-2,1.3c0,0.7,0.8,1.2,2.5,1.9c2.4,0.9,3.5,2.1,3.5,4
C192.6,84.8,190.9,87.1,187.2,87.1L187.2,87.1z M198.4,87.1c-1.5,0-3.1-0.4-3.8-0.8l0.6-2.5c0.8,0.4,2.1,0.8,3.4,0.8
s2.1-0.6,2.1-1.5s-0.6-1.3-2.3-1.9c-2.2-0.8-3.7-2-3.7-4s1.9-4.1,5.1-4.1s2.6,0.3,3.4,0.7l-0.7,2.5c-0.5-0.3-1.5-0.6-2.8-0.6
s-2,0.6-2,1.3c0,0.7,0.8,1.2,2.5,1.9c2.4,0.9,3.5,2.1,3.5,4S202,87.1,198.4,87.1L198.4,87.1z M214.5,86.9h-8.4V73.4h3.1v10.9h5.4
v2.6H214.5z M221.5,87.1c-4,0-6.3-3-6.3-6.9s2.6-7.1,6.6-7.1s6.4,3.1,6.4,6.8C228.2,83.6,225.5,87,221.5,87.1L221.5,87.1z M241.5,81
c0,4.2-2.1,6.1-5.6,6.1s-5.4-1.9-5.4-6.2v-7.5h3.1v7.8c0,2.3,0.9,3.5,2.4,3.5s2.5-1.1,2.5-3.5v-7.8h3V81z M255.6,86.9h-3.2l-2.9-5.2
c-0.8-1.4-1.7-3.2-2.3-4.8l0,0c0,1.8,0.1,3.7,0.1,5.9v4.1h-2.8V73.4h3.6l2.8,4.9c0.8,1.4,1.6,3.1,2.2,4.6l0,0
c-0.2-1.8-0.3-3.6-0.3-5.6v-3.9h2.8L255.6,86.9L255.6,86.9z M269.9,86.3c-0.9,0.3-2.7,0.8-4.5,0.8s-4.2-0.6-5.5-1.8
c-1.2-1.2-1.9-2.9-1.9-4.9c0-4.5,3.3-7,7.7-7s3.1,0.3,3.7,0.7l-0.6,2.4c-0.7-0.3-1.7-0.6-3.1-0.6c-2.5,0-4.5,1.4-4.5,4.4
s1.7,4.4,4.2,4.4s1.3,0,1.5-0.2v-2.8h-2.1v-2.4h5L269.9,86.3L269.9,86.3z M281,86.9h-8.6V73.4h8.3v2.5h-5.3v2.8h5v2.5h-5v3.2h5.5
v2.5H281z"/>
</svg>
</template>

