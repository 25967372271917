<template>
<svg version="1.1" id="logo-fitphysiotitlis" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 168.4 90.4" style="enable-background:new 0 0 168.4 90.4;" xml:space="preserve">
<polygon class="st0" points="126.3,75.9 126,78 122.1,78 120.6,90.2 118,90.2 119.5,78 115.6,78 115.8,75.9 "/>
<polygon class="st0" points="128.9,90.2 126.3,90.2 128.1,75.9 130.6,75.9 "/>
<polygon class="st0" points="142.9,75.9 142.6,78 138.7,78 137.2,90.2 134.6,90.2 136.1,78 132.2,78 132.4,75.9 "/>
<polygon class="st0" points="145.5,88.1 150.7,88.1 150.4,90.2 142.6,90.2 144.4,75.9 147,75.9 "/>
<polygon class="st0" points="155,90.2 152.4,90.2 154.1,75.9 156.7,75.9 "/>
<path class="st0" d="M166.4,78.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3-0.1-0.4-0.2
c-0.1-0.1-0.3-0.2-0.5-0.4c-0.2-0.1-0.4-0.2-0.7-0.3c-0.3-0.1-0.6-0.2-0.9-0.2c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.3-0.7,0.4
c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.3,0.1,0.6,0.2,0.8c0.2,0.2,0.4,0.4,0.6,0.5c0.3,0.2,0.5,0.3,0.9,0.4
c0.3,0.1,0.7,0.2,1,0.4c0.3,0.1,0.7,0.3,1,0.5c0.3,0.2,0.6,0.4,0.9,0.6c0.3,0.3,0.5,0.6,0.6,0.9c0.2,0.4,0.2,0.8,0.2,1.3
c0,0.7-0.1,1.4-0.4,2c-0.2,0.6-0.6,1.2-1,1.6c-0.5,0.5-1,0.8-1.6,1.1c-0.6,0.3-1.4,0.4-2.2,0.4c-0.4,0-0.8,0-1.2-0.1
c-0.4-0.1-0.8-0.2-1.1-0.4c-0.4-0.2-0.7-0.4-1-0.6c-0.3-0.2-0.6-0.5-0.8-0.8l0.9-1.2c0.1-0.1,0.2-0.2,0.3-0.2
c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.5,0.3
c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.5,0.1,0.8,0.1c0.8,0,1.3-0.2,1.8-0.6c0.4-0.4,0.6-1,0.6-1.8c0-0.3-0.1-0.6-0.2-0.8
c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.5-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-1-0.3c-0.3-0.1-0.7-0.3-1-0.4c-0.3-0.2-0.6-0.4-0.9-0.6
c-0.2-0.3-0.5-0.6-0.6-0.9c-0.2-0.4-0.2-0.8-0.2-1.4c0-0.6,0.1-1.2,0.3-1.8s0.6-1.1,1-1.5c0.4-0.4,0.9-0.8,1.5-1
c0.6-0.3,1.3-0.4,2-0.4c0.4,0,0.8,0,1.1,0.1c0.4,0.1,0.7,0.2,1,0.3c0.3,0.1,0.6,0.3,0.9,0.5c0.3,0.2,0.5,0.4,0.7,0.7L166.4,78.5z"/>
<polygon class="st0" points="15.8,27.6 5.8,27.6 4.8,35.8 13.3,35.8 13,38.2 4.5,38.2 3.3,48 0.3,48 3.1,25.2 16.1,25.2 "/>
<polygon class="st0" points="20.4,48 17.4,48 20.2,25.2 23.1,25.2 "/>
<polygon class="st0" points="43.1,25.2 42.7,27.7 36,27.7 33.5,48 30.6,48 33.1,27.7 26.3,27.7 26.6,25.2 "/>
<polygon class="st0" points="86.4,27.6 76.4,27.6 75.5,35.3 83.6,35.3 83.2,37.7 75.2,37.7 74.2,45.6 84.2,45.6 83.9,48 70.9,48 
73.7,25.2 86.7,25.2 "/>
<path class="st0" d="M101.1,28.7c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.6-0.3
c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.7-0.4-1.1-0.6c-0.4-0.2-1-0.3-1.7-0.3c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.3,0.9
c-0.4,0.4-0.6,0.8-0.8,1.3c-0.2,0.5-0.3,1-0.3,1.6c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.7,1,1c0.4,0.3,0.9,0.5,1.4,0.7
c0.5,0.2,1.1,0.4,1.6,0.6c0.5,0.2,1.1,0.5,1.6,0.7c0.5,0.3,1,0.6,1.4,1c0.4,0.4,0.7,0.9,1,1.4c0.3,0.6,0.4,1.2,0.4,2
c0,1.1-0.2,2.1-0.6,3.1c-0.4,1-0.9,1.8-1.6,2.5c-0.7,0.7-1.5,1.3-2.5,1.7c-1,0.4-2.1,0.6-3.3,0.6c-1.4,0-2.6-0.3-3.6-0.8
c-1.1-0.5-1.9-1.3-2.6-2.2l1-1.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.7,0.4c0.2,0.2,0.5,0.5,0.9,0.8
c0.4,0.3,0.8,0.6,1.3,0.8c0.5,0.2,1.2,0.4,2,0.4c0.7,0,1.4-0.1,2-0.4c0.6-0.2,1.1-0.6,1.5-1c0.4-0.4,0.7-0.9,0.9-1.5
c0.2-0.6,0.3-1.2,0.3-1.9c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.6-0.7-1-1c-0.4-0.3-0.9-0.5-1.4-0.7c-0.5-0.2-1.1-0.4-1.6-0.6
c-0.6-0.2-1.1-0.4-1.6-0.7c-0.5-0.3-1-0.6-1.4-1c-0.4-0.4-0.8-0.9-1-1.5c-0.3-0.6-0.4-1.3-0.4-2.1c0-0.9,0.2-1.8,0.5-2.7
c0.3-0.9,0.8-1.6,1.5-2.3c0.6-0.7,1.4-1.2,2.3-1.6c0.9-0.4,1.9-0.6,3.1-0.6c1.2,0,2.2,0.2,3.1,0.7c0.9,0.5,1.7,1.1,2.3,1.9
L101.1,28.7z"/>
<path class="st0" d="M116.7,28.7c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.6-0.3
c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.7-0.4-1.1-0.6c-0.4-0.2-1-0.3-1.7-0.3c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.3,0.9
c-0.4,0.4-0.6,0.8-0.8,1.3c-0.2,0.5-0.3,1-0.3,1.6c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.7,1,1c0.4,0.3,0.9,0.5,1.4,0.7
c0.5,0.2,1.1,0.4,1.6,0.6c0.6,0.2,1.1,0.5,1.6,0.7c0.5,0.3,1,0.6,1.4,1c0.4,0.4,0.8,0.9,1,1.4c0.3,0.6,0.4,1.2,0.4,2
c0,1.1-0.2,2.1-0.6,3.1c-0.4,1-0.9,1.8-1.6,2.5c-0.7,0.7-1.5,1.3-2.5,1.7c-1,0.4-2.1,0.6-3.3,0.6c-1.4,0-2.6-0.3-3.6-0.8
c-1.1-0.5-1.9-1.3-2.6-2.2l1.1-1.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.7,0.4c0.2,0.2,0.5,0.5,0.9,0.8
c0.4,0.3,0.8,0.6,1.3,0.8c0.5,0.2,1.2,0.4,2,0.4c0.7,0,1.4-0.1,2-0.4c0.6-0.2,1.1-0.6,1.5-1c0.4-0.4,0.7-0.9,0.9-1.5
c0.2-0.6,0.3-1.2,0.3-1.9c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.6-0.7-1-1c-0.4-0.3-0.9-0.5-1.4-0.7c-0.5-0.2-1.1-0.4-1.6-0.6
c-0.6-0.2-1.1-0.4-1.6-0.7c-0.5-0.3-1-0.6-1.4-1c-0.4-0.4-0.8-0.9-1-1.5c-0.3-0.6-0.4-1.3-0.4-2.1c0-0.9,0.2-1.8,0.5-2.7
c0.3-0.9,0.8-1.6,1.5-2.3c0.6-0.7,1.4-1.2,2.3-1.6c0.9-0.4,1.9-0.6,3.1-0.6c1.2,0,2.2,0.2,3.2,0.7c0.9,0.5,1.7,1.1,2.3,1.9
L116.7,28.7z"/>
<path class="st0" d="M61.7,12.8c-6.4,5.1-9.5-3.2-3.9-7.3c5.4-3.4,8.2,1.8,4.7,6.4L61.7,12.8z"/>
<path class="st0" d="M69.9,16.9c-1.6,3.1-6.6,16.3-7.4,17.3c-1.8,2.3-5.4-9.6-7.9-8.7c-1.8,0.7-15.2,20.2-16,23.1
c-0.5,1.8-0.4,2.5,0.3,4.1c7.6-3.9,12.2-21.9,15.8-21.4c2.4,0.3,7,11.3,8.6,10c0.4-0.3,10.4-23.7,9.7-25.2c-0.5-1-1.9-0.8-2.6,0.2
L69.9,16.9z"/>
<path class="st0" d="M63.3,20.1c-3,2.7-8,7.1-11.4,2.4c-1.3-1.8-1.4-9.8-1.9-12.9c-0.2-1.1-3.7-15.4,0.1-6.7
c5.1,11.9-1.4,29.9,14.5,11c3.7-4.5,9.8-11.8,16.2-9.6c4.2,1.4-3.8,5.3-5.2,6.1C70.3,13.5,67.7,15.9,63.3,20.1"/>
<path class="st0" d="M1.8,66.2c0,0.4,0.1,0.7,0.2,1c0.1,0.3,0.3,0.5,0.5,0.7c0.2,0.2,0.4,0.3,0.7,0.4s0.6,0.2,0.9,0.2
c0.6,0,1.2-0.1,1.8-0.4c0.6-0.3,1.1-0.6,1.5-1.1L4,62.8c-0.8,0.4-1.3,0.9-1.7,1.5C2,64.9,1.8,65.5,1.8,66.2 M6.6,55.4
c0.5,0,0.9,0.1,1.3,0.2c0.4,0.1,0.7,0.4,1,0.6c0.3,0.3,0.5,0.6,0.6,0.9c0.1,0.3,0.2,0.7,0.2,1.1c0,0,0,0,0,0.1s0,0,0,0.1l-1,0.2
c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.4-0.3c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.3-0.3-0.5-0.4
c-0.2-0.1-0.4-0.1-0.7-0.1c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,1
c0,0.4,0.1,0.8,0.2,1.2c0.2,0.4,0.4,0.8,0.7,1.2l3.2,4.1c0.3-0.4,0.5-0.9,0.7-1.4c0.2-0.5,0.3-0.9,0.4-1.4c0-0.1,0.1-0.2,0.1-0.3
c0.1-0.1,0.2-0.1,0.3-0.1h1.2c-0.1,0.8-0.3,1.5-0.6,2.3c-0.3,0.7-0.7,1.4-1.2,2.1l2.4,3h-1.5c-0.1,0-0.3,0-0.4,0
c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2l-1-1.2c-0.6,0.6-1.3,1-2.1,1.4c-0.8,0.3-1.6,0.5-2.4,0.5
c-0.5,0-1-0.1-1.5-0.2c-0.4-0.2-0.8-0.4-1.2-0.7c-0.3-0.3-0.6-0.7-0.8-1.1C0.1,67.4,0,66.9,0,66.4c0-0.5,0.1-1,0.3-1.5
C0.4,64.5,0.7,64,1,63.7c0.3-0.4,0.7-0.7,1.1-1c0.4-0.3,0.9-0.6,1.3-0.8C3.1,61.4,3,61,2.8,60.5c-0.1-0.4-0.2-0.8-0.2-1.3
c0-0.5,0.1-1.1,0.3-1.5c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.4,0.8-0.6,1.3-0.8C5.5,55.5,6,55.4,6.6,55.4z"/>
<path class="st1" d="M20.7,57l-0.7,6H22c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.4,1.1-0.7c0.3-0.3,0.5-0.7,0.7-1.1
c0.2-0.4,0.2-0.9,0.2-1.4c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.4-0.9-0.5C23.6,57,23.2,57,22.7,57L20.7,57
L20.7,57z M19.8,64.4l-0.7,5.3h-1.8L19,55.5h3.9c0.8,0,1.4,0.1,2,0.3c0.6,0.2,1,0.4,1.4,0.8c0.4,0.3,0.6,0.7,0.8,1.2
c0.2,0.5,0.3,1,0.3,1.5c0,0.8-0.1,1.4-0.4,2.1c-0.2,0.6-0.6,1.2-1.1,1.6c-0.5,0.5-1,0.8-1.7,1.1c-0.7,0.3-1.5,0.4-2.4,0.4L19.8,64.4
L19.8,64.4z"/>
<polygon class="st1" points="38.9,69.7 37.1,69.7 37.9,63.2 30.9,63.2 30.2,69.7 28.3,69.7 30,55.5 31.9,55.5 31.1,61.9 38,61.9 
38.8,55.5 40.7,55.5 "/>
<path class="st1" d="M47.9,64l-0.7,5.7h-1.9l0.7-5.6l-3.8-8.6H44c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.3l2.4,5.8
c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.3-0.6l3.8-5.8c0.1-0.1,0.2-0.2,0.3-0.3
c0.1-0.1,0.2-0.1,0.4-0.1h1.5L47.9,64z"/>
<path class="st1" d="M61.9,57.7c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.4-0.2c-0.1-0.1-0.3-0.2-0.5-0.4
c-0.2-0.1-0.4-0.3-0.7-0.4c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.5,0.8
c-0.1,0.3-0.2,0.6-0.2,1c0,0.3,0.1,0.6,0.2,0.9c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.2,0.6,0.3,0.9,0.4c0.3,0.1,0.7,0.3,1,0.4
c0.3,0.1,0.7,0.3,1,0.5c0.3,0.2,0.6,0.4,0.9,0.6c0.3,0.2,0.5,0.5,0.6,0.9c0.2,0.3,0.2,0.8,0.2,1.2c0,0.7-0.1,1.3-0.3,1.9
c-0.2,0.6-0.6,1.1-1,1.6c-0.4,0.4-1,0.8-1.6,1.1c-0.6,0.3-1.3,0.4-2.1,0.4c-0.8,0-1.6-0.2-2.3-0.5c-0.7-0.3-1.2-0.8-1.6-1.4l0.7-0.9
c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.3,0.6,0.5c0.2,0.2,0.5,0.3,0.8,0.5
c0.3,0.1,0.7,0.2,1.2,0.2c0.5,0,0.9-0.1,1.2-0.2c0.4-0.1,0.7-0.3,0.9-0.6c0.3-0.3,0.5-0.6,0.6-0.9c0.1-0.4,0.2-0.8,0.2-1.2
c0-0.4-0.1-0.7-0.2-0.9c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.2-0.5-0.3-0.9-0.4c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.1-0.7-0.3-1-0.4
c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.5-0.5-0.6-0.9c-0.2-0.4-0.2-0.8-0.2-1.3c0-0.6,0.1-1.1,0.3-1.7c0.2-0.5,0.5-1,0.9-1.4
c0.4-0.4,0.9-0.7,1.4-1c0.6-0.2,1.2-0.4,1.9-0.4c0.7,0,1.4,0.1,2,0.4c0.6,0.3,1.1,0.7,1.4,1.2L61.9,57.7z"/>
<polygon class="st1" points="65.5,69.7 63.7,69.7 65.4,55.5 67.3,55.5 "/>
<path class="st1" d="M80.2,61.6c0-0.7-0.1-1.4-0.3-2c-0.2-0.6-0.4-1.1-0.8-1.5c-0.3-0.4-0.8-0.7-1.2-1c-0.5-0.2-1-0.3-1.7-0.3
c-0.8,0-1.5,0.2-2.2,0.5c-0.7,0.3-1.2,0.8-1.7,1.3c-0.5,0.6-0.8,1.3-1.1,2.1c-0.3,0.8-0.4,1.7-0.4,2.8c0,0.7,0.1,1.4,0.3,2
c0.2,0.6,0.4,1.1,0.8,1.5c0.3,0.4,0.8,0.7,1.2,0.9c0.5,0.2,1,0.3,1.7,0.3c0.8,0,1.5-0.2,2.2-0.5c0.7-0.3,1.2-0.8,1.7-1.3
c0.5-0.6,0.8-1.3,1.1-2.1C80.1,63.6,80.2,62.7,80.2,61.6 M82.1,61.6c0,0.8-0.1,1.6-0.3,2.3c-0.2,0.7-0.4,1.4-0.7,2
c-0.3,0.6-0.7,1.2-1.1,1.7c-0.4,0.5-0.9,0.9-1.5,1.3c-0.6,0.3-1.2,0.6-1.8,0.8c-0.6,0.2-1.3,0.3-2,0.3c-0.9,0-1.7-0.2-2.4-0.5
c-0.7-0.3-1.3-0.7-1.8-1.3c-0.5-0.6-0.9-1.2-1.1-2c-0.3-0.8-0.4-1.6-0.4-2.5c0-1.2,0.2-2.3,0.6-3.3c0.4-1,0.9-1.9,1.6-2.6
c0.7-0.7,1.5-1.3,2.4-1.7c0.9-0.4,1.9-0.6,3-0.6c0.9,0,1.7,0.2,2.4,0.5c0.7,0.3,1.3,0.8,1.8,1.3c0.5,0.6,0.9,1.2,1.1,2
C82,59.9,82.1,60.7,82.1,61.6z"/>
<polygon class="st1" points="93.2,55.5 93,57 88.8,57 87.3,69.7 85.4,69.7 87,57 82.8,57 83,55.5 "/>
<polygon class="st1" points="104,69.7 102.1,69.7 102.9,63.2 96,63.2 95.2,69.7 93.3,69.7 95.1,55.5 96.9,55.5 96.1,61.9 
103.1,61.9 103.9,55.5 105.7,55.5 "/>
<polygon class="st1" points="116.8,57 110.5,57 109.9,61.8 115,61.8 114.8,63.3 109.8,63.3 109.2,68.2 115.4,68.2 115.2,69.7 
107.1,69.7 108.9,55.5 117,55.5 "/>
<path class="st1" d="M121.3,56.9l-0.7,5.5h1.8c0.6,0,1.1-0.1,1.5-0.2c0.4-0.2,0.8-0.4,1.1-0.7c0.3-0.3,0.5-0.6,0.7-1
c0.1-0.4,0.2-0.8,0.2-1.3c0-0.7-0.2-1.3-0.7-1.7c-0.5-0.4-1.1-0.6-2-0.6L121.3,56.9L121.3,56.9z M120.4,63.8l-0.7,6h-1.8l1.7-14.2
h3.7c0.8,0,1.4,0.1,2,0.2c0.6,0.2,1,0.4,1.4,0.7c0.4,0.3,0.6,0.7,0.8,1.1c0.2,0.4,0.3,0.9,0.3,1.4c0,0.6-0.1,1.1-0.3,1.6
c-0.2,0.5-0.4,0.9-0.7,1.3c-0.3,0.4-0.7,0.7-1.2,1c-0.5,0.3-1,0.5-1.5,0.6c0.2,0.1,0.4,0.3,0.5,0.6l3.1,5.7H126
c-0.3,0-0.6-0.1-0.7-0.4l-2.7-5.1c-0.1-0.2-0.2-0.3-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1L120.4,63.8L120.4,63.8z"/>
<path class="st1" d="M132.3,64.4h4.9l-1.3-5.9c0-0.2-0.1-0.4-0.1-0.6c0-0.2-0.1-0.4-0.1-0.7c-0.1,0.3-0.2,0.5-0.3,0.7
c-0.1,0.2-0.2,0.4-0.3,0.6L132.3,64.4z M140.2,69.7h-1.4c-0.2,0-0.3,0-0.4-0.1s-0.2-0.2-0.2-0.3l-0.8-3.5h-5.8l-1.6,3.5
c-0.1,0.1-0.1,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0.1h-1.4l7-14.2h1.9L140.2,69.7z"/>
<path class="st1" d="M145.3,57l-0.7,6h2.1c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.4,1.1-0.7c0.3-0.3,0.5-0.7,0.7-1.1
c0.1-0.4,0.2-0.9,0.2-1.4c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.4-0.9-0.5c-0.3-0.1-0.7-0.2-1.2-0.2
L145.3,57L145.3,57z M144.4,64.4l-0.7,5.3h-1.8l1.7-14.2h3.9c0.8,0,1.4,0.1,2,0.3c0.6,0.2,1,0.4,1.4,0.8c0.4,0.3,0.6,0.7,0.8,1.2
c0.2,0.5,0.3,1,0.3,1.5c0,0.8-0.1,1.4-0.4,2.1c-0.2,0.6-0.6,1.2-1.1,1.6c-0.5,0.5-1,0.8-1.7,1.1c-0.7,0.3-1.5,0.4-2.3,0.4
L144.4,64.4L144.4,64.4z"/>
<polygon class="st1" points="155.1,69.7 153.3,69.7 155,55.5 156.9,55.5 "/>
<polygon class="st1" points="168.2,57 162,57 161.4,61.8 166.4,61.8 166.2,63.3 161.2,63.3 160.6,68.2 166.9,68.2 166.7,69.7 
158.6,69.7 160.3,55.5 168.4,55.5 "/>
</svg>
</template>

