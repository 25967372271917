<template>
  <div v-if="checkinsLoaded">
    <template v-for="([day, level]) in checkins" :key="day">
      <div class="centered-line">{{ getDateTitleFormat(day) }}</div>
      <template v-for="item in level" :key="item.id">
        <div class="scheduler-list-item grid-x" :class="{ active: checkedIn(item.enrolledMembers)}">
          <div class="cell grid-x">
            <div class="auto cell">
              <p class="ck-overflow-ellipsis" style="margin-left:3px;margin-top:1px">
                <i class="fa-light fa-whistle"></i>
                {{ item.coursetext }} {{ (item.cancelled) ? "(Abgesagt)" : ""}}
              </p>
              <p class="ck-overflow-ellipsis" style="margin-left:3px">
                <i class="fa-light fa-calendar-clock"></i>
                Datum: {{ formatDateTs(item.startTs*1000) }}
              </p>
            </div>
            <div class="shrink cell">
              <input type="button" value="Mehr Info" class="cx-button tiny" style="margin-right:2px;margin-top:3px" @click="toggleDesc(item.id)"/> <!--Toggle extra info about each course-->
            </div>
          </div>
          <div :id="'details'+item.id" style="display:none">
            <p class="ck-overflow-ellipsis" style="margin-left:3px">
              <i class="fa-solid fa-align-justify"></i>
              Grund der Absage: {{ item.cancelReason }}
            </p>
            <p class="ck-overflow-ellipsis" style="margin-left:3px">
              <i class="fa-light fa-location-dot"></i>
              Standort: {{ item.locationtext }} 
            </p>
            <p class="ck-overflow-ellipsis" style="margin-left:3px"> 
              <i class="fa-light fa-user"></i>
              Trainer: {{ item.trainertext }}
            </p>
            <p class="ck-overflow-ellipsis" style="margin-left:3px" v-if="!item.cancelled">
              <a>
                <i class="fa-light fa-users"></i>
                <span>Teilnehmer: {{  item.enrolledMembers.length }} / {{ item.enrolledMax }}</span>
              </a>
            </p>
          </div>
        </div>

        <div>
          <div v-if="!checkedIn(item.enrolledMembers) || item.cancelled">
            <button @click="checkinCourse(item.id)" style="margin-left:5px;margin-bottom:3px" 
              :style="{color: !canCheckin(item.enrolledMembers.length, item.enrolledMax, item.startTs, item.cancelled) ? 'grey' : ''}"
              class="cx-button tiny" 
              :disabled="!canCheckin(item.enrolledMembers.length, item.enrolledMax, item.startTs, item.cancelled)"
              :readOnly="!canCheckin(item.enrolledMembers.length, item.enrolledMax, item.startTs, item.cancelled)"
            >
              <i class="fa-solid fa-right-to-bracket"></i> 
              Anmelden 
            </button>
          </div> <!-- Add functionality for checkin/checkout -->
          <div v-else>
            <button @click="checkoutCourse(item.id)" style="margin-left:5px;margin-bottom:3px" class="cx-button tiny"
              :disabled="!canCheckout(item.deregisterLatest, item.startTs)"
              :readOnly="!canCheckout(item.deregisterLatest, item.startTs)"
              :style="{color: !canCheckout(item.deregisterLatest, item.startTs) ? 'grey' : ''}" 
            >
              <i class="fa-solid fa-right-to-bracket"></i>
              Abmelden 
            </button>
          </div>
        </div>
        <!-- shrink cell -->
        <!-- grid-x -->
      </template>
    </template>
  </div>
  <!-- template v-for -->

  <!-- bottomspace or if no-content -->
  &nbsp;
  <!-- bottomspace or if no-content -->

</template>

<script>

let currentUser;
let checkins = ref(new Map());
let timestamp;
let dateFilter = ref(Date.now());

let checkinsLoaded = ref(false);

const dayName = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

import $ from 'jquery';
import Foundation from 'foundation-sites';

import { ref } from 'vue';

import auth from "../auth";
import notify from 'devextreme/ui/notify';
import { 
  apihost, 
} from "../api";

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function fetchCheckins(){
  fetch(`${apihost}/vue/checkin/getcheckin/0/list?rangets=${Date.now() / 1000}`)
    .then(handleErrors)
    .then(response => response.text())
    .then(async result => {
      const data = JSON.parse(result);
      checkins.value = new Map();
      for(const checkin of data) {
        const date = new Date(checkin.startTs * 1000);
        date.setHours(0, 0, 0, 0);

        if(!checkins.value.has(date.valueOf())) {
          checkins.value.set(date.valueOf(), []);
        }

        /** @type {Object[]} **/
        const level = checkins.value.get(date.valueOf());
        level.push(checkin);
        checkins.value.set(date.valueOf(), level);
      } 

      checkinsLoaded.value = true;
      checkins.value = new Map(checkins.value);
    })
    .catch((e) => {notify("Kurse nicht verfügbar", "error", 3000); console.log(e)});
}

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

export default {
  name: 'training-data',

  props: {
    templateData: Object,
    date : String,
    place : String,
  },

  components: {
  },

  mounted() {
    checkinsLoaded.value = false;

    this.reveal = new Foundation.Reveal($('#revealDialogCourse'), {
      animationIn: 'scale-in-up',
    });

    //timestamp
    this.timestamp = this.printTimestamp();

    fetchCheckins();
  },

  data() {
    return {
      apihost,
      msg: 'Course (part)',
      currentUser,
      timestamp,
      dateFilter,
      checkins,
      checkinsLoaded,
    };
  },

  methods: {

    openReveal(id) {
      const revealhtml = document.getElementById("revealHtml");
      const revealcontent = document.getElementById('revealContent'+id);
      const revealhtmlcontent = revealcontent.innerHTML;

      //Set Content before open
      revealhtml.innerHTML = revealhtmlcontent;

      this.reveal.open();
    },

    formatDateTs(ts) {
      //console.log(ts);
      const timestamp = parseInt(ts); // timestamp
      const date = new Date(timestamp);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

      // Get Hours part from the timestamp
      var hours = '0' + date.getHours();
      hours = hours.slice(-2);

      // Get Minutes part from the timestamp
      var minutes = '0' + date.getMinutes();
      minutes = minutes.slice(-2);

      const formattedDate = new Intl.DateTimeFormat('de-DE', options).format(date) + ', ' + hours+':'+minutes;

      return formattedDate;
    },

    printTimestamp: function () {
      return Date.now();
    },

    toggleDesc(itemid){
      let curr = document.getElementById("details" + itemid).style.display;
      document.getElementById("details" + itemid).style.display = (curr === "none") ? 'block' : 'none';
    },

    checkinCourse(id) {
      fetch(apihost+'/'+`/vue/checkin/getcheckin/${id}/checkin?customerid=${currentUser.id}`)
        .then(handleErrors)
        .then(response => response.text())
        .then(() => {
          fetchCheckins()
        })
        .catch(() => notify("Kurse nicht verfügbar", "error", 3000));
    }, 

    checkoutCourse(id) {
      fetch(apihost+'/'+
        `/vue/checkin/getcheckin/${id}/checkout?customerid=${currentUser.id}`)
        .then(handleErrors)
        .then(response => response.text())
        .then(() => {
          fetchCheckins();
        })
        .catch(() => notify("Kurse nicht verfügbar", "error", 3000));
    },

    checkedIn(enrolledMembers) {
      return enrolledMembers.find((e) => e.memberid == currentUser.id) != undefined;
    },

    canCheckin(enrolledCount, enrolledMax, startTs, cancelled) {
      return (enrolledCount < enrolledMax) && (startTs > Date.now() / 1000) && !cancelled; 
    },

    canCheckout(deregisterLatest, startTs) {
      return Date.now() / 1000 < (startTs - deregisterLatest * 60);
    },

    dateFilterChange(){
      /*fetch(apihost+'/'+currentUser.Language+
        `/vue/scheduler/getscheduler/id/action?filtertype=1`)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          SchedulerItems.value = data;
        })
        .catch(() => notify("Kurse nicht verfügbar", "error", 3000));
      */
    },

    getDayOfTs(ts) {
      let time = new Date(ts);
      time.setHours(0, 0, 0);
      return time.valueOf();
    },

    getDateTitleFormat(ts) {
      const date = new Date(ts);
      return `${dayName[date.getDay() - 1]}, ${date.getDate()}/${date.getMonth() + 1}`; 
    }
  },

};


</script>
<style>
  .centered-line {
    display: flex;
    align-items: center;
    text-align:center;
  }

  .centered-line::before,
  .centered-line::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid black;
    margin: 0 10px;
  }
</style>
