<template>

  <div v-if="currentUser.Role == 'Customer'" class="-content-block -hide">

    <h2 class="app-title hide">
      {{ title }}
    </h2>

    <div class="tabs-content tabs-content-customer" :data-tabs-content="'tabsDashboard'+currentUser.id">
    
      <div class="tabs-panel" :class="tab1" :id="'panelDashboard_1'+currentUser.id">
        <!-- <p>subtitle panel1</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-dumbbell"></i>
          Training
        </h2>


        <div class="dx-card hide">
          <div class="user-base-wrap has-padding">
            <!-- <p>Training Dashboard</p> -->
          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 

      </div><!-- /tabs-panel -->     

      <div class="tabs-panel" :class="tab2" :id="'panelDashboard_2'+currentUser.id">
        <!-- <p>subtitle panel2</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-dumbbell"></i>
          Übungen
        </h2>

        <div class="dx-card">
          <div class="user-base-wrap -has-padding">
            <!-- <p>Trainingplan</p> -->


            <div class="customer-datagrid">

              <DxDataGrid
                ref="myDataGrid"
                id="gridContainer"
                :data-source="trainingDataSource"
                :focused-row-enabled="false"
                :column-auto-width="false"
                :column-hiding-enabled="false"
                :allow-column-reordering="false"
                :show-borders="false"
              >

              <DxPaging :page-size="50" />
              <DxPager :show-page-size-selector="true" :show-info="true" />
              <DxFilterRow :visible="false" />

              <DxEditing
                :allow-updating="false"
                :allow-deleting="false"
                :allow-adding="false"
                mode="row"
              />  

              <DxColumn
                alignment='left' 
                data-field="Search"
                :caption="'Plan'"
                :placeholder="'Suchen'"
                :hiding-priority="99"
                cell-template="subjectTemplate"

              />
              <template #subjectTemplate="{ data: content }">

              <div class="grid-x align-middle">

                <div class="shrink cell">
                  <div style="width: 0; height: 50px;">
                  </div>

                </div><!-- /shrink cell main -->

                <div class="auto cell">
                  <div class="grid-x align-middle">

                    <div class="shrink cell">
                      <!-- row 1 -->
                      <div class="fixedwidth-listitem">
                        <p class="ck-overflow-ellipsis strong small">
                          <span v-if="content.data.Subject">
                            {{ content.data.Subject }}
                          </span>
                          <span v-else class="alert-color">
                            <i class="fa-light fa-triangle-exclamation"></i> Bezeichnung
                          </span>
                        </p>
                      </div><!-- /fixedwidth-listitem -->
                    </div><!-- /shrink cell -->

                    <div class="shrink cell">
                      <!-- row 4 -->
                      <div class="fixedwidth-listitem">
                        <p class="ck-overflow-ellipsis -strong small">
                          <span v-if="content.data.DateStart && content.data.DateStop">
                            {{ formatDate(content.data.DateStart) }} – {{ formatDate(content.data.DateStop) }}
                          </span>
                          <span v-else class="alert-color">
                            <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
                          </span>
                        </p>
                      </div><!-- /fixedwidth-listitem -->
                    </div><!-- /shrink cell -->

                    <div class="shrink cell">
                      <!-- row 2 -->
                      <div class="fixedwidth-listitem">
                        <p class="ck-overflow-ellipsis -strong small">
                          <span v-if="content.data.ExercisesPlanned.length">
                            {{ content.data.ExercisesPlanned.length }} Training
                          </span>
                          <span v-else class="alert-color">Training
                            <i class="fa-light fa-triangle-exclamation"></i> Training
                          </span>
                        </p>
                      </div><!-- /fixedwidth-listitem -->
                    </div><!-- /shrink cell -->

                    <div class="shrink cell hide">
                      <!-- row 3 -->
                      <div class="fixedwidth-listitem">
                        <p class="ck-overflow-ellipsis -strong small">
                          <span v-if="content.data.MembersPlanned.length">
                            {{ content.data.MembersPlanned.length }} Mitglieder
                          </span>
                          <span v-else class="alert-color">
                            <i class="fa-light fa-triangle-exclamation"></i> Mitglieder
                          </span>
                        </p>
                      </div><!-- /fixedwidth-listitem -->
                    </div><!-- /shrink cell -->

                  </div><!-- /grid-x sub-->

                </div><!-- /auto cell main -->
              </div><!-- /grid-x main -->

              </template>

              <DxMasterDetail
                  :enabled="true"
                  template="masterTrainingDetailTemplate"
                />
                <template #masterTrainingDetailTemplate="{ data: content }">
                  <TrainingDetailTemplate
                    :template-data="content"
                  />
                </template>

              </DxDataGrid>

            </div><!-- customer-datagrid -->


          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 

      <div class="tabs-panel" :class="tab3" :id="'panelDashboard_3'+currentUser.id">
        <h2 class="app-title">
          <i class="fa-light fa-whistle"></i>
          Kurse
        </h2>
        <div style="display: flex; align-items: center" class="hide">
          <div class="dx-field-label">
            <h2 style="color:black;font-size: large"> Datum </h2>
          </div>
          <div class="dx-field-value">
            <DxDateBox
              ref="datebox"
              type='datetime'
              :value="date"
              :min="date"
            />
          </div>
        </div>
        <div style="display: flex; align-items: center;" class="hide">
          <div class="dx-field-label">
            <h2 style="color:black;font-size: large;">Standort</h2>
          </div>
          <div class="dx-field-value">
            <DxDropDownBox
              v-model:placeholder="selectedLoc"
              ref="locationbox"
              label="Standort wählen"
              labelMode="static"
              v-model:opened="isOpen"
            >
              <DxList
                :data-source="locations"
                selection-mode="single"
                @selection-changed="(event) => {this.isOpen=false; this.selectedLoc=event.addedItems[0];}"
              >
              </DxList>
            </DxDropDownBox>
          </div>
        </div>
        <div style="float:right; padding-top:2%; padding-bottom:2%" class="hide">
            <button @click="changeDateLoc()" class="cx-button "> Filtern </button>
        </div>
        <div class="dx-card" :style="{'overflow-y' : 'scroll', height: `${document.defaultView.screen.height * 0.5}px`, clear: 'both'}">   
          <CourseDetailTemplate
            :template-data="currentUser"
            :date="formatDateTs(date)"
            :place="place"
          />
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 

      <div class="tabs-panel" :class="tab4" :id="'panelDashboard_4'+currentUser.id">
        <!-- <p>subtitle panel4</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-calendar-days"></i>
          Termine
        </h2>

        <div class="dx-card">
          <div class="user-base-wrap has-padding">
            <!-- <p>Termine</p> -->
          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 

      <div class="tabs-panel" :class="tab5" :id="'panelDashboard_5'+currentUser.id">
        <!-- <p>subtitle panel5</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-list-ul"></i>
          Training
        </h2>

        <div class="dx-card">

          <TrainingDataTemplate
            :template-data="exerciseDone"
          />

        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 

    </div><!-- /tabs-content --> 

    <ul :id="'tabsDashboard'+currentUser.id" class="tabs grid-x grid-margin-x small-up-2" data-tabs style="border: none; background: none; margin: 0 -15px;">
      <li class="cell tabs-title hide" :class="tab1">
        <a :href="'#panelDashboard_1'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_1 start -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertTrainingplan"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-dumbbell"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Training</p>
            </div>
          </div>
        </a>
      </li>
      <li class="cell tabs-title" :class="tab2">
        <a :href="'#panelDashboard_2'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_2 adress -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertExercise"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-dumbbell"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Übungen</p>
            </div>
          </div>
        </a>
      </li>
      <li class="cell tabs-title" :class="tab3">
        <a :href="'#panelDashboard_3'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_3 foto -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertCourse"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-whistle"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Kurse</p>
            </div>
          </div>
        </a>
      </li>
      <li class="cell tabs-title" :class="tab4">
        <a :href="'#panelDashboard_4'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_4 gesundheit -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertDates"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-calendar-days"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Termine</p>
            </div>
          </div>
        </a>
      </li>
      <li class="cell tabs-title" :class="tab5">
        <a :href="'#panelDashboard_5'+currentUser.id" aria-selected="true" style="padding: 0;">
          <!-- panelDashboard_5 messungen -->
          <div class="dx-card dashboard-item">
            <!-- alert-icon -->
            <AppAlertDashboard
              :count="countAlertData"
            />
            <!-- /alert-icon -->
            <div class="text-center">
              <i class="dashboard-icon fa-light fa-list-ul"></i>
            </div>
            <div class="text-center">
              <p class="dashboard-text">Training</p>
            </div>
          </div>
        </a>
      </li>

    </ul>

  </div>

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->
  
</template>

<script>

import {
  DxDataGrid, 
  DxFilterRow, 
  DxColumn, 
  DxEditing, 
  DxPager, 
  DxPaging, 
  DxMasterDetail,
  //DxButton,
  //DxLookup, 
} from 'devextreme-vue/data-grid';

import TrainingDetailTemplate from '../appdetail/training-detail.vue';
import TrainingDataTemplate from '../appdetail/training-data.vue';

import CourseDetailTemplate from '../appdetail/course-detail.vue';


import AppAlertDashboard from "../appdetail/app-alert-dashboard";

import CustomStore from 'devextreme/data/custom_store';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import auth from "../auth";
import { 
  apihost, 
} from "../api";
import { DxDateBox, DxDropDownBox, DxList } from 'devextreme-vue';

let currentUser;
let customerId;
let fetchUser;
let exerciseDone;
let locations;
let date = Date.now();
let place;

let tab1 = 'is-active';
let tab2 = '';
let tab3 = '';
let tab4 = '';
let tab5 = '';

let countAlertTrainingplan = 0;
let countAlertExercise = 0;
let countAlertCourse = 0;
let countAlertDates = 0;
let countAlertData = 0;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;

  } // e.data
}); //auth

// async function update() {
//   const currentTime = Math.floor(Date.now() / 1000);
//     console.log(currentTime);
//     console.log('app-trainplan-loaded');
// }

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

const trainingDataSource = new CustomStore({
  key: 'id',
  load: () => {
    return fetch(apihost+'/de/vue/exerciseplan/list/?Customerid='+customerId)
    .then(handleErrors)
    .then(response => response.text())
    .then(data => {
    //console.log(`response text`, text)
      return JSON.parse(data)
    })
    .catch(() => { throw 'Network error' });
  },
  insert: (values) => {
    //console.log(values);
    const key = '0';
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/insert'+objString+'&Customerid='+customerId, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  remove: (key) => {
    //console.log(key);
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/remove', {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  update: (key, values) => {
    // console.log(key);
    // console.log(values);
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/save'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  }

});

export default {

  async mounted() {

    await this.fetchAlert(); 
    await this.fetchLocations();

    //await this.fetchCustomer();

    // await update();
    // setInterval(update, 1000);


    let url = window.location.href;
    let pos = url.indexOf("?");
    let tab = '';

    if(pos > 0){
      tab = url.substring(pos+1, 100);
    }

    if(tab == 'exercise'){
      this.tab1 = '';
      this.tab2 = 'is-active';
    }

    if(tab == 'course'){
      this.tab1 = '';
      this.tab3 = 'is-active';
    }

    if(tab == 'dates'){
      this.tab1 = '';
      this.tab4 = 'is-active';
    }

    if(tab == 'data'){
      this.tab1 = '';
      this.tab5 = 'is-active';
    }

    this.tabsDashboard = new Foundation.Tabs($('#tabsDashboard'+this.currentUser.id), {
      matchHeight: false
    });

    if(this.currentUser){
      customerId = this.currentUser.id;
      place = this.currentUser.Location;
    }

    this.timestamp = this.printTimestamp();

    auth.getUser().then((e) => {
      if(e.data){
        // console.log(e.data);
        // console.log(e.data.id);
        // console.log(e.data.Language);

        //DATA exerciseDone
        fetch(apihost+'/de/vue/member/getmember/'+e.data.id+'/view')
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        //console.log(data);
        this.fetchUser = data;
        this.exerciseDone = this.fetchUser.Exercisedone;
        //console.log(this.exerciseDone);
        

        })
        .catch(() => { throw 'Network error' });
      } // e.data
    }); //auth

  },  

  components: {

    DxDataGrid,
    DxFilterRow,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    DxMasterDetail,
    DxDateBox,
    DxDropDownBox,
    DxList,

    TrainingDetailTemplate,
    TrainingDataTemplate,
    CourseDetailTemplate,
    AppAlertDashboard,

  },

  data() {
    return {
      apihost,
      title: 'Training',
      currentUser,
      customerId,
      fetchUser,
      exerciseDone,
      locations,
      date,
      place,
      document,

      tab1,
      tab2,
      tab3,
      tab4,
      tab5,

      countAlertTrainingplan,
      countAlertExercise,
      countAlertCourse,
      countAlertDates,
      countAlertData,

      trainingDataSource,

      selectedLoc: 'Standort',
      isOpen: false,

    };
  },
  methods: {

    // async fetchCustomer() {

    //   fetch(apihost+'/'+currentUser.Language+'/vue/member/getmember/'+currentUser.id+'/view')
    //   .then(handleErrors)
    //   .then(response => response.text())
    //   .then(result => {
    //     const data = JSON.parse(result);
    //     console.log(data);
    //   })
    //   .catch(() => { throw 'Network error' });

    // },

    async fetchAlert() {

      fetch(apihost+'/'+currentUser.Language+'/de/vue/member/memberalert/'+currentUser.id)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result);
        // console.log(data);

        this.countAlertTrainingplan = data.countAlertTrainingplan;
        this.countAlertExercise = data.countAlertExercise;
        this.countAlertCourse = data.countAlertCourse;
        this.countAlertDates = data.countAlertDates;
        this.countAlertData = data.countAlertData;

      })
      .catch(() => { throw 'Network error' });

    },

    async fetchLocations(){
      fetch(apihost + '/vue/location/list/')
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
        this.locations = JSON.parse(data);
      })
      .catch(() => { throw 'Network Error' });
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
      return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
    },

    formatDateTs(ts) {
      //console.log(ts);
      const timestamp = parseInt(ts); // timestamp
      const date = new Date(timestamp);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

      // Get Hours part from the timestamp
      var hours = '0' + date.getHours();
      hours = hours.slice(-2);
      //console.log(hours);

      // Get Minutes part from the timestamp
      var minutes = '0' + date.getMinutes();
      minutes = minutes.slice(-2);
      //console.log(minutes);

      // Get Seconds part from the timestamp
      var seconds = '0' + date.getSeconds();
      seconds = seconds.slice(-2);
      //console.log(seconds);
      
      //var time = '00:00:00';
      var time = hours+':'+minutes+':'+seconds;
      //console.log(time);

      const formattedDate = new Intl.DateTimeFormat('de-DE', options).format(date) + ' | ' + time;
      //console.log(formattedDate);

      return formattedDate;
    },



    printTimestamp: function () {
      return Date.now();
    },

    changeDateLoc: function (){
      date = this.$refs.datebox.value;
      place = this.$refs.locationbox.placeholder;
    },

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
