<template>
<svg version="1.1" id="logo-meintraining" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 1524 404.3" style="enable-background:new 0 0 1524 404.3;" xml:space="preserve">
<path class="st0" d="M159,178c21.3,0,42.7,0,64,0c3.7-23,7.3-46,11-69c-18.3,0-36.7,0-55,0c0-0.3,0-0.7,0-1
c24.3-35.7,48.7-71.3,73-107c-29-0.3-58-0.7-87-1c-18.7,31.3-37.3,62.7-56,94c-0.3,0-0.7,0-1,0C88.3,63,68.7,32,49,1
C33,0.7,17,0.3,1,0C0.7,59.3,0.3,118.7,0,178c18.3,0,36.7,0,55,0c0-25.7,0-51.3,0-77c12.3,19.7,24.7,39.3,37,59
c6.2,2.9,18.7,2.3,28,2c12.3-21,24.7-42,37-63c0.7-0.3,1.3-0.7,2-1C159,124.7,159,151.3,159,178z M302,67c0,0.3,0,0.7,0,1
c-24.3,35.7-48.7,71.3-73,107c-0.3,1-0.7,2-1,3c59.7,0,119.3,0,179,0c0-15,0-30,0-45c-29.7,0-59.3,0-89,0c0-7.7,0-15.3,0-23
c24.7,0,49.3,0,74,0c0.3-14.3,0.7-28.7,1-43c-25-0.3-50-0.7-75-1c0-6.7,0-13.3,0-20c28.3,0,56.7,0,85,0c0-15.3,0-30.7,0-46
c-48.7,0-97.3,0-146,0c-3.3,22.3-6.7,44.7-10,67C265.3,67,283.7,67,302,67z M498,1c-19.7-0.3-39.3-0.7-59-1
c-0.3,59.3-0.7,118.7-1,178c20,0,40,0,60,0C498,119,498,60,498,1z M593,98c22.3,26.7,44.7,53.3,67,80c16.3,0,32.7,0,49,0
c0-59.3,0-118.7,0-178c-19,0-38,0-57,0c-0.3,26.7-0.7,53.3-1,80c-22.7-26.7-45.3-53.3-68-80c-16,0-32,0-48,0
c-0.3,59-0.7,118-1,177c19.7,0.3,39.3,0.7,59,1C593,151.3,593,124.7,593,98z M1485,285c13-10.7,26-21.3,39-32c0-0.7,0-1.3,0-2
c-29.6-40.2-111.3-41.3-151-10c-16.9,13.3-25.6,35.1-30,61c-10,58.4,51.5,111.2,111,101c26-4.4,44-12.9,64-21
c7.7-16.9,2.3-54.7,2-76c-17.3-0.3-34.7-0.7-52-1c0,15,0,30,0,45c-35.2,14.7-76.7-10.1-63-55C1413.8,266,1469.7,252.5,1485,285z
M159,268c17.3,0,34.7,0,52,0c0,43.7,0,87.3,0,131c20,0,40,0,60,0c0-43.7,0-87.3,0-131c17.3,0,34.7,0,52,0c0-15.3,0-30.7,0-46
c-54.7,0-109.3,0-164,0C159,237.3,159,252.7,159,268z M472,344c39.8-19.7,45.5-81.2,8-108c-28.4-20.3-92.7-14.9-141-14
c0,59,0,118,0,177c20,0,40,0,60,0c0-14.7,0-29.3,0-44c5.3-0.3,10.7-0.7,16-1c10,15,20,30,30,45c21.3,0,42.7,0,64,0
c-11.7-17.7-23.3-35.3-35-53C473.3,345.3,472.7,344.7,472,344z M399,309c0-13.7,0-27.3,0-41c21.7-1,41.7-1.6,46,15
C452.1,310.5,421.7,310.1,399,309z M571,222c-17.7,58.7-35.3,117.3-53,176c0,0.3,0,0.7,0,1c21,0,42,0,63,0c2.7-9.7,5.3-19.3,8-29
c4.9-4.4,49-2.3,60-2c2.3,10.3,4.7,20.7,7,31c21.7,0,43.3,0,65,0c-17.7-59-35.3-118-53-177C635.7,222,603.3,222,571,222z M599,325
c4.7-18.3,9.3-36.7,14-55c3.7,0,7.3,0,11,0c4.7,18.3,9.3,36.7,14,55C625,325,612,325,599,325z M737,399c20,0,40,0,60,0
c0-59,0-118,0-177c-20,0-40,0-60,0C737,281,737,340,737,399z M950,301c-22.3-26.3-44.7-52.7-67-79c-16.7,0-33.3,0-50,0
c0,59,0,118,0,177c19.3,0,38.7,0,58,0c0-26.3,0-52.7,0-79c0.3,0,0.7,0,1,0c22.3,26.3,44.7,52.7,67,79c16.3,0,32.7,0,49,0
c0-59,0-118,0-177c-19.3,0-38.7,0-58,0C950,248.3,950,274.7,950,301z M1044,398c20,0.3,40,0.7,60,1c0-59,0-118,0-177
c-20,0-40,0-60,0C1044,280.7,1044,339.3,1044,398z M1257,301c-22-25.7-44-51.3-66-77c-6.9-6.3-39.3-2.3-51-2c0,58.7,0,117.3,0,176
c19.7,0.3,39.3,0.7,59,1c0-26.7,0-53.3,0-80c22.3,26.7,44.7,53.3,67,80c16.3,0,32.7,0,49,0c0-59,0-118,0-177c-19.3,0-38.7,0-58,0
C1257,248.3,1257,274.7,1257,301z"/>
<path class="st1" d="M858,151.3c-1-3.6-3-6.6-6-8.9c-1.6-1.2-3.4-2.1-5.3-2.7c-0.6-0.2-1.2-0.4-1.8-0.8c0.9-0.3,1.7-0.5,2.4-1
c2.6-1.6,4.6-3.7,5.9-6.5c1.4-3.1,1.9-6.3,1.6-9.7c-0.2-2.2-0.9-4.2-1.9-6.2c-1.1-2.1-2.7-3.6-4.5-5.1c-1.9-1.5-4.1-2.5-6.5-3.2
c-3.3-1-6.6-1.6-10-1.7c-2.6-0.1-5.2,0-7.7,0c-7.3,0-14.7,0-22,0c-1.2,0-2.4,0-3.6,0c-0.6,0-0.7,0.2-0.7,0.7
c0.1,0.6,0.1,1.2,0.1,1.8c0,22.1,0,44.3,0,66.4c0,0.9-0.1,0.8,0.8,0.8c11.3,0,22.6,0,33.9,0c0.4,0,0.8,0,1.1-0.1
c0.8-0.1,1.6-0.2,2.4-0.2c1.8-0.2,3.7-0.4,5.5-0.7c2.8-0.6,5.5-1.4,8-2.7c4.2-2.3,7.1-5.7,8.3-10.3c0.4-1.4,0.5-2.8,0.6-4.2
C858.6,155.1,858.5,153.2,858,151.3z M808.5,113.6c3.5,0,7,0,10.6,0c3.7,0,7.3,0,11,0c2.5,0,4.9,0.3,7.3,1.1
c1.8,0.6,3.4,1.5,4.9,2.8c1,0.9,1.6,2,2.1,3.2c0.6,1.7,0.8,3.4,0.7,5.2c-0.4,4-2.5,6.8-6.2,8.4c-2,0.8-4,1.3-6.2,1.5
c-1.5,0.1-2.9,0.1-4.4,0.1c-2.7,0-5.5,0-8.2,0c-3.7,0-7.4,0-11.1,0c-0.8,0-0.7,0.1-0.7-0.7c0-6.5,0-12.9,0-19.4c0-0.6,0-1.1-0.1-1.7
C808,113.6,808.1,113.6,808.5,113.6z M848.3,158.6c-0.7,3-2.6,5.1-5.3,6.5c-1.6,0.8-3.3,1.2-5,1.6c-2.2,0.5-4.4,0.5-6.6,0.5
c-3.1,0-6.2,0-9.3,0c0,0,0,0,0,0c-4.5,0-9,0-13.5,0c-0.5,0-0.5,0-0.5-0.5c0-0.5,0-1,0-1.5c0-1.8,0-3.7,0-5.5c0-5,0-10,0-15
c0,0,0-0.1,0-0.1c0-0.7,0-0.7,0.7-0.7c7.5,0,15.1,0,22.6,0c3.1,0,6.1,0.3,9.1,1.2c1.7,0.5,3.3,1.3,4.7,2.4c1.4,1.2,2.4,2.7,2.8,4.5
C848.7,154.2,848.8,156.4,848.3,158.6z"/>
<path class="st1" d="M1442.3,106c0-0.3-0.1-0.4-0.4-0.4c-0.1,0-0.2,0-0.3,0c-3-0.1-6-0.1-9.1-0.1c-0.3,0-0.5,0.1-0.4,0.4
c0,0.1,0,0.2,0,0.4c0,5,0,10,0,15c0,4.6-0.1,9.2,0.1,13.7c0,0.3-0.1,0.4-0.4,0.4c-0.3,0-0.6,0-0.9,0c-12,0-24,0-36,0c-1,0-2,0-3,0
c-0.5,0-0.5-0.1-0.5-0.5c0-0.6,0.1-1.2,0.1-1.8c0-7.7,0-15.4,0-23.1c0-1.2,0-2.4,0.1-3.6c0-0.2,0-0.3,0-0.5
c-0.1-0.4-0.2-0.5-0.6-0.5c-1,0-2,0-3.1,0c-1.9,0-3.7,0-5.6,0c-0.8,0-0.9,0.1-0.9,0.9c0,0.7,0,1.4,0,2.2c0,10.6,0,21.3,0,31.9
c0,0,0,0,0,0c0,7.8,0,15.5,0,23.3c0,3.7,0,7.4,0.1,11c0,0.4,0,0.4,0.5,0.5c0.1,0,0.3,0,0.4,0c2.7,0,5.3,0,8,0c1,0,1,0,1-1
c0-9,0-18,0-27c0-0.7,0-1.5,0-2.2c0-1.1-0.1-1,1-1c2.6-0.1,5.1,0,7.7,0c8.9,0,17.8,0,26.7,0c1.5,0,3,0,4.5,0c0.9,0,0.8-0.1,0.8,0.7
c0,9.9,0,19.8,0,29.7c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3,0,0.5,0c2.8,0,5.7,0,8.5,0c0.9,0,0.9,0,0.9-0.9
c0-1.5,0-2.9,0-4.4c0-8,0-15.9,0-23.9c0-10.7,0-21.4,0-32.1c0-2.2,0-4.5,0-6.7C1442.3,106.9,1442.3,106.4,1442.3,106z"/>
<path class="st1" d="M929.4,166.4c-12.9,0-25.9,0-38.8,0c-0.3,0-0.6,0-0.9,0c-0.5,0-0.5,0-0.5-0.5c0-0.1,0-0.1,0-0.2c0-7,0-14,0-21
c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.3,0.3-0.2c1,0.1,2-0.1,2.9-0.1c4.5,0,9.1,0,13.6,0c5.8,0,11.5,0,17.3,0c1.2,0,1.2,0,1.2-1.2
c0,0,0-0.1,0-0.1c0-2.1,0-4.2,0-6.2c0-0.8,0-0.9-0.9-0.9c-11.3,0-22.7,0-34,0c-0.5,0-0.5,0-0.5-0.5c0-0.6,0-1.2,0-1.8
c0-6.1,0-12.2,0-18.3c0-0.9-0.1-0.8,0.8-0.8c12.3,0,24.6,0,36.9,0c0.5,0,1,0,1.4,0c0.5,0,0.5-0.1,0.5-0.5c0-2.5-0.1-5.1,0.1-7.6
c0-0.4,0-0.4-0.4-0.4c-0.9,0-1.7-0.1-2.6-0.1c-6.4,0-12.8,0-19.2,0c-9,0-18,0-26.9,0c-0.8,0-0.8,0-0.8,0.8c0,11.3,0,22.7,0,34
c0,0,0,0,0,0c0,6.6,0,13.3,0,19.9c0,4.7,0,9.4,0,14.1c0,0.9,0,0.9,0.9,0.9c16.5,0,33,0,49.4,0c0.1,0,0.3,0,0.4,0
c0.3,0,0.4-0.1,0.4-0.4c0-0.2,0-0.3,0-0.5c0-2.4,0-4.8,0-7.2C930.2,166.2,930.1,166.4,929.4,166.4z"/>
<path class="st1" d="M1068.5,125.9c-0.4-3.4-1.6-6.6-3.5-9.5c-2.3-3.3-5.3-5.8-9-7.6c-4.3-2.1-9-3-13.7-3.3c-2.1-0.1-4.3,0-6.4,0
c-7.1,0-14.3,0-21.4,0c-0.7,0-1.4,0-2.1,0.1c-0.3,0-0.5,0.1-0.5,0.4c0,0.2,0,0.3,0,0.5c0,1.2,0,2.3,0,3.5c0,4.9,0,9.9,0,14.8
c0,5.2,0,10.3,0,15.5c0,0,0,0,0,0c0,1.4,0,2.9,0,4.3c0,3.7,0,7.5,0,11.2c0,4.2,0,8.4,0,12.6c0,1.9,0.1,3.8,0,5.7c0,0.1,0,0.3,0,0.4
c0.1,0.6,0.1,0.7,0.8,0.7c2.4,0,4.7,0,7.1,0c0.6,0,1.3,0,1.9,0c0.5,0,0.5,0,0.5-0.5c0-0.1,0-0.3,0-0.4c0-6.1,0-12.1,0-18.2
c0-0.5,0-1-0.1-1.5c-0.1-0.5,0-0.6,0.5-0.6c1,0,2,0,3.1,0c4.9,0,9.9,0,14.8,0c4,0,7.9-0.6,11.7-1.8c4.2-1.3,7.8-3.4,10.9-6.5
c1.6-1.7,2.9-3.6,3.9-5.7C1068.8,135.5,1069.1,130.7,1068.5,125.9z M1058.7,131.7c-0.1,1.6-0.4,3.2-1.1,4.7c-1,2.4-2.6,4.2-4.8,5.7
c-2.5,1.7-5.3,2.5-8.3,3c-2.1,0.3-4.3,0.3-6.4,0.3c-1.9,0-3.7,0-5.6,0c-3.3,0-6.5,0-9.8,0c-0.1,0-0.3,0-0.4,0
c-0.4,0-0.5-0.2-0.5-0.6c0.3-1.1,0.2-2.2,0.2-3.3c0-8.9,0-17.8,0-26.6c0-0.9-0.1-0.8,0.8-0.8c5.2,0,10.5,0,15.7,0
c2.4,0,4.8,0.1,7.2,0.6c2.6,0.5,5.1,1.3,7.2,2.9c2,1.5,3.6,3.3,4.6,5.7C1058.8,126,1058.9,128.8,1058.7,131.7z"/>
<path class="st1" d="M1220.3,174.9c-2-4-3.8-8.1-5.7-12.2c-2.3-5-4.6-10.1-6.9-15.1c-2-4.4-4-8.7-6.1-13.1
c-2.4-5.2-4.8-10.4-7.2-15.5c-2-4.3-4-8.6-6-13c-0.1-0.3-0.3-0.4-0.6-0.4c-1.6,0-3.3,0-4.9,0c-1.3,0-2.5,0-3.8,0
c-0.5,0-0.9,0.2-1.1,0.7c-0.9,2.2-1.9,4.3-2.9,6.5c-3.3,7.2-6.6,14.4-9.9,21.6c-2,4.3-4,8.6-6,13c-1.2,2.6-2.3,5.1-3.5,7.7
c-1.7,3.6-3.4,7.3-5,10.9c-1.4,3-2.7,6-4.1,9c0,0.1-0.1,0.2,0,0.3c0.2,0.1,0.4,0.1,0.5,0.1c2.8,0,5.7,0,8.5,0c0.1,0,0.2,0,0.4,0
c0.4,0,0.7-0.1,0.9-0.6c0.1-0.3,0.3-0.6,0.4-0.9c1-2.2,2-4.5,3-6.7c0.7-1.5,1.3-3,2-4.6c0.6-1.3,1.2-2.6,1.6-3.9
c0.2-0.6,0.5-0.8,1.1-0.8c1.1,0,2.2-0.2,3.4-0.1c0.9,0.1,1.9,0.1,2.8,0.1c6.4,0,12.7,0,19.1,0c3.8,0,7.6-0.1,11.4,0
c0.6,0,0.6,0,0.7,0.5c0.7,1.6,1.4,3.2,2.1,4.8c1.6,3.7,3.3,7.4,5,11c0.5,1.2,0.3,1.2,1.9,1.2c2.8,0,5.5,0,8.3,0c0.2,0,0.4,0,0.6-0.1
C1220.4,175.1,1220.4,175,1220.3,174.9z M1198.3,149.8c-0.8-0.1-1.6-0.2-2.4-0.2c-9,0-18,0-27,0c-0.2,0-0.5,0-0.8-0.1
c5.1-11.3,10.1-22.6,15.2-34c0.3,0.3,0.5,0.7,0.7,1.1c1,2.1,1.8,4.2,2.8,6.3c2.3,5,4.5,10.1,6.7,15.1c1.3,3,2.7,6,4,9
c0.4,0.8,0.7,1.7,1.2,2.5c0,0,0,0.1,0,0.2C1198.6,149.9,1198.5,149.8,1198.3,149.8z"/>
<path class="st1" d="M1285.4,154c-0.4-4.1-2.2-7.5-5.4-10.2c-2.6-2.2-5.6-3.6-8.8-4.7c-3.9-1.3-7.8-2.3-11.8-3.2
c-2.8-0.7-5.7-1.4-8.4-2.3c-2.4-0.9-4.6-2-6.4-3.9c-0.6-0.6-1.1-1.3-1.4-2.1c-0.7-1.7-0.8-3.5-0.5-5.3c0.6-2.9,2.4-5,4.9-6.6
c1.6-1,3.4-1.6,5.3-2c2.1-0.4,4.3-0.5,6.4-0.6c2.5-0.1,5.1,0.2,7.6,0.7c3.7,0.7,7.1,1.9,10.4,3.7c0.6,0.3,1.2,0.7,1.7,1.2
c0.1-0.1,0.1-0.2,0.2-0.2c1.1-2.4,2.1-4.8,3.2-7.2c0.1-0.3,0-0.4-0.1-0.5c-0.5-0.4-1-0.7-1.6-1c-3.2-1.9-6.7-3-10.3-3.9
c-1.7-0.4-3.4-0.7-5.1-0.9c-2.9-0.3-5.9-0.3-8.8-0.2c-3.5,0.2-6.8,0.8-10.1,2c-2.8,1-5.4,2.5-7.6,4.5c-2.2,1.9-3.7,4.3-4.7,7
c-1.3,3.4-1.4,6.9-0.7,10.4c0.4,1.8,1,3.6,2.1,5.1c1.9,2.6,4.2,4.7,7.2,6.1c4.1,2,8.5,3.3,12.9,4.4c3.4,0.8,6.8,1.5,10.1,2.6
c1.5,0.5,3,1,4.5,1.7c1.3,0.6,2.4,1.5,3.5,2.4c0.7,0.7,1.3,1.5,1.7,2.4c0.8,2.2,0.8,4.4,0,6.6c-0.8,2.3-2.5,3.9-4.6,5
c-2.5,1.4-5.3,2.1-8.2,2.2c-2.9,0.1-5.8,0.2-8.7-0.1c-2.8-0.2-5.4-0.9-8.1-1.8c-2.3-0.8-4.5-1.8-6.7-3.1c-1.2-0.7-2.4-1.4-3.4-2.3
c-0.5-0.5-0.6-0.5-0.9,0.2c0,0.1-0.1,0.1-0.1,0.2c-1,2.1-1.9,4.3-3.1,6.3c-0.2,0.4-0.2,0.5,0.2,0.8c1.3,1.1,2.6,2.1,4.1,2.9
c5.8,3.2,12.1,4.9,18.7,5.4c2.8,0.2,5.7,0.2,8.5-0.1c2.2-0.2,4.3-0.6,6.4-1.1c1.8-0.5,3.5-1.1,5.2-1.9c0.9-0.4,1.7-1,2.5-1.5
c1.2-0.7,2.2-1.5,3.2-2.5c2.3-2.3,3.8-5,4.7-8C1285.5,158.6,1285.6,156.3,1285.4,154z"/>
<path class="st1" d="M1363.2,164.8c-1.9-1.8-3.9-3.7-5.8-5.5c-0.3-0.3-0.3-0.3-0.7,0c-2.3,2.5-5.1,4.4-8.2,5.7
c-2.7,1.2-5.6,1.9-8.6,2.1c-2.6,0.2-5.2,0.1-7.9-0.2c-3.3-0.5-6.5-1.6-9.5-3.2c-6.2-3.5-10.2-8.7-12.2-15.4c-0.6-2.2-0.9-4.4-1-6.6
c-0.1-2.5,0.1-5.1,0.6-7.5c0.5-2.3,1.3-4.4,2.4-6.5c2.2-4.3,5.5-7.6,9.6-10.1c4-2.3,8.3-3.7,12.9-3.8c3.5-0.1,6.9,0,10.3,1
c2,0.6,4,1.4,5.9,2.4c2.1,1.2,3.9,2.7,5.6,4.3c0.4,0.4,0.4,0.4,0.8,0c1.9-1.8,3.8-3.6,5.7-5.3c0.1-0.1,0.2-0.1,0.2-0.2
c0.1-0.2,0.2-0.4-0.1-0.5c-0.6-0.4-1.1-1-1.6-1.5c-2-1.9-4.3-3.6-6.7-4.8c-3.6-1.8-7.4-3.1-11.3-3.7c-2.3-0.4-4.6-0.5-7.1-0.5
c-1.1-0.1-2.6,0-4,0.1c-3.9,0.3-7.6,1.3-11.1,2.7c-5.4,2.2-10.1,5.5-13.9,10c-2.7,3.2-4.7,6.8-6.2,10.6c-1.1,3-1.7,6.2-1.9,9.4
c-0.2,2.9-0.1,5.8,0.4,8.7c0.5,3.3,1.5,6.4,2.9,9.4c2,4.1,4.6,7.7,8,10.8c2.6,2.4,5.6,4.3,8.8,5.9c2.7,1.3,5.6,2.2,8.6,2.8
c2.2,0.5,4.4,0.7,6.6,0.8c3.5,0.2,7-0.1,10.4-0.8c2.2-0.5,4.3-1.1,6.4-1.9c4.6-1.8,8.4-4.5,11.9-7.9c0.1-0.1,0.1-0.2,0-0.4
C1363.3,164.9,1363.3,164.8,1363.2,164.8z"/>
<path class="st1" d="M1523.2,105.7c-0.2-0.3-0.5-0.2-0.8-0.2c-2.1-0.1-4.2,0-6.3-0.1c-0.6,0-1.2,0-1.8,0.1c-0.6,0-1,0.3-1.3,0.8
c-0.2,0.4-0.4,0.7-0.6,1.1c-4.5,7.2-8.9,14.4-13.4,21.6c-2.6,4.2-5.2,8.3-7.7,12.5c-0.3,0.6-0.6,0.6-1,0c-0.1-0.2-0.2-0.4-0.4-0.6
c-0.4-0.6-0.7-1.3-1.1-1.9c-3-4.8-6-9.6-8.9-14.5c-3.5-5.7-7-11.5-10.6-17.2c-0.3-0.5-0.6-0.9-0.8-1.4c-0.1-0.4-0.4-0.5-0.8-0.5
c-0.2,0-0.4,0-0.6,0c-2.7,0-5.4-0.1-8.1,0c-0.2,0-0.5,0-0.7,0c-0.2,0-0.5-0.1-0.6,0.2c-0.2,0.3,0,0.5,0.2,0.7
c0.3,0.4,0.6,0.8,0.9,1.2c1.1,1.6,2,3.2,3,4.8c2.7,4.4,5.4,8.7,8.1,13c2.6,4.1,5.1,8.3,7.7,12.4c2.3,3.6,4.4,7.2,6.7,10.8
c0.3,0.5,0.6,1.1,1,1.6c0.4,0.4,0.5,0.9,0.5,1.5c0,0.5-0.1,0.9-0.1,1.4c-0.1,3.3-0.1,6.7,0,10c0,3.2,0,6.3,0,9.5c0,0.7,0,1.5,0,2.2
c0,0.4,0,0.4,0.5,0.5c0.1,0,0.2,0,0.3,0c2.7,0,5.4,0,8.1,0c1,0,1-0.1,1-1c0-1.5,0-3.1,0-4.6c0-5.4,0-10.9,0-16.3c0-0.6,0-1.2,0-1.8
c0-0.5,0.2-0.9,0.4-1.3c1-1.5,1.9-3.1,2.9-4.6c1.2-1.9,2.4-3.8,3.6-5.8c1.3-2.2,2.7-4.4,4.1-6.7c2.3-3.7,4.6-7.5,6.9-11.2
c1.2-2,2.5-4,3.7-6c1.8-3,3.6-5.9,5.5-8.9c0.1-0.2,0.3-0.4,0.4-0.6C1523,106.2,1523.4,106.1,1523.2,105.7z"/>
<path class="st1" d="M1135.3,166.4c-12.2,0-24.5,0-36.7,0c-0.2,0-0.4,0-0.6,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.6,0.1-1.2,0.1-1.8
c0-3.8,0-7.6,0-11.5c0-15.4,0-30.7,0-46.1c0-0.3,0-0.5,0-0.8c0-0.2-0.2-0.3-0.4-0.3c-1,0.1-1.9,0-2.9,0c-2,0-3.9,0-5.9,0
c-0.8,0-0.8,0.1-0.8,0.7c0,0.2,0,0.4,0,0.6c0,0.8,0,1.7,0,2.5c0,10.4,0,20.8,0,31.2c0,0,0,0,0,0c0,9.4,0,18.8,0,28.2
c0,2,0,3.9,0.1,5.9c0,0.6,0.1,0.6,0.7,0.6c0.1,0,0.1,0,0.2,0c15.6,0,31.2,0,46.7,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.3-0.3
c0-0.1,0-0.3,0-0.4c0-2.5,0-4.9,0-7.4C1136.1,166.3,1136.2,166.4,1135.3,166.4z"/>
<path class="st1" d="M959.4,106.3c0-0.6,0-0.7-0.7-0.7c-2.9-0.1-5.9-0.1-8.8,0c-0.9,0-0.8,0-0.8,0.8c0,11.3,0,22.7,0,34c0,0,0,0,0,0
c0,11.3,0,22.7,0,34c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.3,0,0.4,0c2.8,0,5.7,0,8.5,0c0.9,0,0.9,0,0.9-0.9
c0-1.6,0-3.2,0-4.9c0-18.7,0-37.4,0-56.1C959.3,111.1,959.3,108.7,959.4,106.3z"/>
</svg>
</template>

