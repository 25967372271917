<template>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">
    <div class="grid-x grid-margin-x">
      <div class="large-auto cell">
        <h2><i class="dx-icon nav-icon fa-light fa-file-invoice"></i> {{ title }}</h2>
      </div>
      <div class="large-shrink cell -hide">
        <a :href="$router.resolve({name: 'sap-invoicelist-all'}).href" class="cx-button tiny" style="margin-right: 10px; opacity: 0.3;"><i class="fa-light fa-file-invoice"></i> alle</a>
        <a :href="$router.resolve({name: 'sap-invoicelist-due'}).href" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-invoice"></i> offen</a>
        <a :href="$router.resolve({name: 'sap-invoicelist-paid'}).href" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-invoice"></i> bezahlt</a>
      </div><!-- shrink cell -->
    </div><!-- grid-x -->

    <div style="height: 10px;"></div>

    <div class="dx-card no-paddings dx-card-has-minheight">
  
        <DxDataGrid
          id="gridContainer"
          :data-source="customDataSource"
  
          :focused-row-enabled="false"
          :column-auto-width="true"
          :column-hiding-enabled="true"
          
          :allow-column-reordering="true"
          :show-borders="false"
  
          @content-ready="getTotalCount"

          @editing-start="logEvent('EditingStart')"
          @init-new-row="logEvent('InitNewRow')"
          @row-inserting="logEvent('RowInserting')"
          @row-inserted="logEvent('RowInserted')"
          @row-updating="logEvent('RowUpdating')"
          @row-updated="logEvent('RowUpdated')"
          @row-removing="logEvent('RowRemoving')"
          @row-removed="logEvent('RowRemoved')"
          @saving="logEvent('Saving')"
          @saved="logEvent('Saved')"
          @edit-canceling="logEvent('EditCanceling')"
          @edit-canceled="logEvent('EditCanceled')"
        >
  
        <DxPaging :page-size="500" />
        <DxPager :show-page-size-selector="false" :show-info="true" />
        <DxFilterRow :visible="true" />
  
        <DxEditing
          :allow-updating="false"
          :allow-deleting="true"
          :allow-adding="false"
          mode="row"
        />

        <DxColumn
          alignment='left' 
          data-field="Search"
          :caption="totalCount + ' ' + title"
          :placeholder="'Suchen'"
          cell-template="subjectTemplate"
          :hiding-priority="99"
        />
        <DxColumn
          alignment='left' 
          data-field="LocationName"
          :caption="''"
          :placeholder="'Standort'"
          :editor-options="locationEditorOptions"
          editor-type="dxSelectBox"
          :width="200" 
          cell-template="locationTemplate"
          :hiding-priority="99"
        />

        <template #locationTemplate="{ data: content }">

          <div class="fixedwidth-listitem">

          <p class="ck-overflow-ellipsis small strong">
            {{ content.data.LocationName }}
          </p>

          </div>

        </template>

        <template #subjectTemplate="{ data: content }">

          <!-- search: {{  content.data.Search }} -->

          <div class="grid-x align-middle">

            <div class="shrink cell">

              <!-- avatar -->
              <div class="user-thumb" style="margin: 2px 10px 2px 0;"
                v-bind:style="{ 'background-image': 'url(' + content.data.CustomerPicthumb + ')' }" >
              </div>
              <!-- /avatar -->

            </div><!-- /shrink cell main -->

            <div class="auto cell">
              <div class="grid-x align-middle">

                <div class="shrink cell">
                  <!-- row 1 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis strong small">
                      <span v-if="content.data.CustomerSubject">
                        {{ content.data.CustomerSubject }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Kunde
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis small">
                      RE-{{ content.data.id }}
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 2 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis strong small" :id="'titleDateinvoice'+content.data.id">
                      <span v-if="content.data.DateInvoice">
                        {{ formatDate(content.data.DateInvoice) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Rechnungsdatum
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis small">
                      <span>{{ content.data.AboSubject }} </span>
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->
                
                <div class="shrink cell">
                  <!-- row 3 -->
                  <div class="fixedwidth-listitem">
                    <!--
                    rateid: {{  content.data.rateid }}
                    key: {{  content.data.key }}
                    -->

                    <p class="ck-overflow-ellipsis strong small" :id="'titlePrice'+content.data.id">
                      <span v-if="content.data.Rateprice">
                        CHF {{ formatPrice(content.data.Rateprice) }}
                      </span>

                      <span v-if="content.data.Price">
                        CHF {{ formatPrice(content.data.Price) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Preis
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis small" :id="'titleRates'+content.data.id">
                      <span v-if="content.data.Rates">
                        Rate {{  content.data.rateid }} von {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Raten
                      </span>
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->
                
                <div class="shrink cell align-self-top">
                  <!-- row 4 -->
                  <div class="fixedwidth-listitem">

                    <!-- PaidList -->
                    <div class="-hide" v-for="(item, index) in content.data.PaidList" :key="index">

                      <p v-if="item.id == content.data.rateid" class="ck-overflow-ellipsis small success-color">
                        <span v-if="item.Date">
                          <i class="fa-light fa-circle-check"></i>
                          {{ formatDate(item.Date) }}
                          |
                        </span>
                        <span v-if="item.Price">
                          CHF {{ formatPrice(item.Price) }}
                        </span>
                      </p>
                    </div><!-- /v-for PaidList -->

                    <!-- DueList -->
                    <div class="-hide" v-for="(item, index) in content.data.DueList" :key="index">
                      <p v-if="item.id == content.data.rateid" class="ck-overflow-ellipsis small alert-color">
                        <span v-if="item.Date">
                          <i class="fa-light fa-triangle-exclamation"></i>
                          {{ formatDate(item.Date) }}
                          |
                        </span>
                        <span v-if="item.Price">
                          CHF {{ formatPrice(item.Price) }}
                        </span>
                      </p>
                    </div><!-- /v-for DueList -->

                    <p class="ck-overflow-ellipsis small" style="font-size: 9px;">
                    <!-- here stringReference  -->
                    {{ content.data.stringReference }}
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->
              </div><!-- /grid-x sub-->

            </div><!-- /auto cell main -->
          </div><!-- /grid-x main -->

        </template>

        <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
        />
        <template #masterDetailTemplate="{ data: content }">
          <DetailTemplate
            :template-data="content"
          />
        </template>
  
      </DxDataGrid>
  
    </div><!-- dx-card -->
    
    </div><!-- content-block -->
  
    <div v-else class="content-block">
      Oh no 😢
    </div><!-- content-block -->
  
    <div class="content-block hide">
      <div id="events">
  
        <div class="grid-x grid-margin-x"> 
          <div class="auto cell"> 
            <div class="caption">
              Fired events:
            </div>
            <ul class="menu vertical">
            <li
              v-for="(event, index) in events"
              :key="index"
            >{{ event }}</li>
            </ul>
          </div>
          <div class="shrink cell"> 
            <DxButton
              id="clear"
              text="Clear"
              @click="clearEvents()"
            />
          </div>
        </div><!-- grid-x -->
  
      </div><!-- events -->
    </div><!-- content-block -->
  
  </template>
  
  <script>
  
  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxButton,
    DxMasterDetail,
    //DxSelection,
    // DxScrolling,

    //DxLookup, 
  } from 'devextreme-vue/data-grid';
  
  import CustomStore from 'devextreme/data/custom_store';
  import DetailTemplate from '../parts/invoice-item-detail-master.vue';

  import auth from "../auth";

  import { 
    apihost, 
  } from "../api";
  //console.log(apihost);
    
  const colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3
  }

  let currentUser;
  let totalCount;
  
  auth.getUser().then((e) => {
    if(e.data){
      currentUser = e.data;
      // console.log(currentUser.id);
      // console.log(currentUser.Constant.languages);
      // console.log(currentUser.Email);
      // console.log(currentUser.Language);
    } // e.data
  }); //auth
  
  function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
      return fetch(apihost+'/'+currentUser.Language+'/vue/invoice/list/')
      .then(handleErrors)
      .then(response => response.text())
      .then(data => {
      //console.log(`response text`, text)
      return JSON.parse(data)
      })
      .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
      //console.log(values);
      const key = '0';
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/insert'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/remove', {
        // method: 'GET',
      })
        .then(handleErrors)
        .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }
      
  });
  
  export default {
  
    mounted() {

      //redirect to Member App
      if(currentUser.Role == 'Customer'){
        const currentUrl = window.location.href;
        const redirecturl = currentUrl.replace("/#/sap-invoicelist", "/#/dashboard");
        window.location.href = redirecturl;
      }

      this.timestamp = this.printTimestamp();

    },
  
    components: {
      DxDataGrid,
      DxMasterDetail,
      DxFilterRow,
      DxColumn,
      DxEditing,
      DxPager,
      DxPaging,
      DxButton,
      DetailTemplate,
      // DxForm,
      // DxGroupItem,
      // DxSimpleItem,
      // DxButtonItem,
      // DxLabel,
      // DxFileUploader,
      // DxDropDownBox,
      // DxSelection,
      // DxScrolling,
      //DxTextBox,
      //DxRequiredRule,
      //DxCompareRule,
      //DxPatternRule,
      //DxRangeRule,
      //DxEmailRule,
      //DxStringLengthRule,
      //DxAutocomplete,
      //DxAsyncRule,
      //DxLookup,


    },
    data() {
      return {
        apihost,
        totalCount,

        title: currentUser.Translation.vueappNavMemberInvoices,
        events: [],
        formInstance: null,
        // statuses,
        // priorities,
        // invoicetypes,
        // invoicecategory,
        currentUser,
        customDataSource,
        colCountByScreen,

        // //Option 1
        // option1DataSource,
        // option1Columns,
        // option1BoxValue,
        // //changeOption1,
        // //readyOption1,

        // //Option 2
        // option2DataSource,
        // option2Columns,
        // option2BoxValue,
        // //changeOption2,
        // //readyOption2,
  
        calendarOptions: { 
          displayFormat: "dd.MM.yyyy"
        },
        buttonOptions: {
          text: currentUser.Translation.vueappInvoiceFormSubmit,
          type: 'success',
          useSubmitBehavior: true,
        },
        dateBoxOptions: {
          invalidDateExerciseoption2:
            'The date must have the following format: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidExerciseoption2: 'The phone must have a correct USA phone format',
        },
  
        statusPattern: /^[^0-9]+$/,
        priorityPattern: /^[^0-9]+$/,
        invoicePattern: /^[^0-9]+$/,
  
        // phonePattern: /^[02-9]\d{9}$/,
        // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
        
        validationRules: {
  
          // status: [
          //   { type: 'required', invoice: currentUser.Translation.vueappTaskStatusExerciseoption2 },
          // ],
          // priority: [
          //   { type: 'required', invoice: currentUser.Translation.vueappTaskPriorityExerciseoption2 },
          // ],
        },
        //statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        //priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
        // statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
        // priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
        locationEditorOptions: { items: currentUser.Constant.locations, searchEnabled: true },
      };
    },
    methods: {

      getTotalCount(e) {
        this.totalCount = e.component.totalCount();
      },

      formatDate(dateString) {
        const date = new Date(dateString);
        // Then specify how you want your dates to be formatted
        //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
        return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
      },

      formatPrice(value) {
        //https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
        let val = (value/1).toFixed(2).replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
      },

      validateForm(e) {
        e.component.validate();
      },
      
      logEvent(eventName) {
        //console.log(this.events);
        this.events.unshift(eventName);
      },
      clearEvents() {
        this.events = [];
      },
  
      printTimestamp: function () {
          return Date.now();
      },
  
    },
  };
  
  
</script>

<style>

</style>
    