import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

//LAYOUTS
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import registerLayout from "./layouts/register-card.vue";
import appLayout from "./layouts/member-app.vue";
import shopLayout from "./layouts/shop-card";

//SHOP OPEN
import ShopHome from './shop/shop-home';
import ShopProduct from './shop/shop-product';
import ShopCart from './shop/shop-cart';
import ShopCheckout from './shop/shop-checkout';
import ShopPurchase from './shop/shop-purchase';

//VIEWS BASE
import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/task-page";
import About from './views/about-page';


//MEMBER APP
import CustomerDashboard from './app/app-dashboard';
import CustomerProfile from './app/app-profile';
import CustomerTrainingplan from './app/app-trainingplan';
import CustomerContract from './app/app-contract';
import CustomerShop from './app/app-shop';

import CustomerCheckin from './app/app-checkin';
import CustomerMeasurement from './app/app-measurement';
import CustomerMore from './app/app-more';


//STUDIO APP

//00-infinite
import MemberInfinite from './views/sap-memberinfinite';
import ContractInfinite from './views/sap-contractinfinite';
import InvoiceInfinite from './views/sap-invoiceinfinite';

//00-member
import MemberDashboard from './views/sap-dashboard';
import MemberActive from './views/sap-memberactive';
import MemberInactive from './views/sap-memberinactive';
import MemberBlocked from './views/sap-memberblocked';
import MemberInterested from './views/sap-memberinterested';
import MemberAll from './views/sap-memberall';
import MemberCheckin from './views/sap-membercheckin';

//01-scheduler
import Scheduler from './views/sap-scheduler';
//02-course
import CoursePlan from './views/sap-courseplan';
//03-exercise
import ExercisePlan from './views/sap-exerciseplan';
//04-task
import TaskList from './views/sap-tasklist';
//05-message
import MessageList from './views/sap-messagelist';
//06-contract
import ContractList from './views/sap-contractlist';
import ContractListActive from './views/sap-contractlist-active';
import ContractListInactive from './views/sap-contractlist-inactive';
import ContractListExtended from './views/sap-contractlist-extended';
import ContractListNotextended from './views/sap-contractlist-notextended';
import ContractListReminder from './views/sap-contractlist-reminder';
import ContractListMailsent from './views/sap-contractlist-mailsent';
import ContractListAbo from './views/sap-contractlist-abo';
import ContractListSignature from './views/sap-contractlist-signature';
import ContractListInvoice from './views/sap-contractlist-invoice';
import ContractListEven from './views/sap-contractlist-even';
import ContractListOdd from './views/sap-contractlist-odd';
//reminder
import ContractReminder from './views/sap-contractreminder';
//07-invoice
import InvoiceList from './views/sap-invoicelist';
import InvoiceListAll from './views/sap-invoicelist-all';
import InvoiceListDue from './views/sap-invoicelist-due';
import InvoiceListPaid from './views/sap-invoicelist-paid';
//08-shop
//import ShopBag from './views/sap-shopbag';
import ShopSale from './views/sap-shopsale';
//09-statistic
import Statistic from './views/sap-statistic';
/*
import Statistic1 from './views/sap-statistic1';
import Statistic2 from './views/sap-statistic2';
import Statistic3 from './views/sap-statistic3';
import Statistic4 from './views/sap-statistic4';
*/
//90-setting
import SettingCompany from './views/sap-settingcompany';
import SettingModule from './views/sap-settingmodule';
import SettingEmployee from './views/sap-settingemployee';
import SettingEmployeeInactive from './views/sap-settingemployee-inactive';
import SettingCourse from './views/sap-settingcourse';
import SettingExercise from './views/sap-settingexercise';
import SettingHealth from './views/sap-settinghealth';
import SettingAboItem from './views/sap-settingaboitem';
import SettingShopItem from './views/sap-settingshopitem';


//VIEWS OLD
import Members from './views/members-page';
import Contracts from './views/contracts-page';
import Checkin from './views/checkin-page';
import Calendar from './views/calendar-page';
import Message from './views/message-page';
import Video from './views/video-page';
import Training from './views/training-page';
import Shop from './views/shop-page';
import Task from './views/task-page';
import Analytic from './views/analytic-page';
import Setting from './views/setting-page';

//FOUNDATION
import Index from './views/index-example';
import Grid from './views/grid-example';
import Typography from './views/typography-example';
import Button from './views/button-example';
import Accordion from './views/accordion-example';
import Tabs from './views/tabs-example';
import Reveal from './views/reveal-example';
import Tooltip from './views/tooltip-example';
import Dropdownmenu from './views/dropdownmenu-example';
import Drilldownmenu from './views/drilldownmenu-example';
import Accordionmenu from './views/accordionmenu-example';

//DEVEXPRESS
import DxJsonreadonly from './views/dx-jsonreadonly';
import DxJsonstore from './views/dx-jsonstore';
import DxCustomstore from './views/dx-customstore';
import DxMemberstore from './views/dx-memberstore';
import DxEmployeestore from './views/dx-employeestore';
import DxMemberpopup from './views/dx-memberpopup';
import DxMemberlist from './views/dx-memberlist';
import DxMemberaccordion from './views/dx-memberaccordion';
import DxTaskstore from './views/dx-taskstore';
import DxContractstore from './views/dx-contractstore';
import DxInvoicestore from './views/dx-invoicestore';
import DxCoursestore from './views/dx-coursestore';
import DxMeasurementstore from './views/dx-measurementstore';
import DxExercisestore from './views/dx-exercisestore';
import DxExercisegroupstore from './views/dx-exercisegroupstore';
import DxExerciseplanstore from './views/dx-exerciseplanstore';
import DxMessagestore from './views/dx-messagestore';
import DxAbostore from './views/dx-abostore';
import DxShopstore from './views/dx-shopstore';
import DxTeststore from './views/dx-teststore';
import DxTestform from './views/dx-testform';
import DxDemoform from './views/dx-demoform';
import DxPopupdemo from './views/dx-popupdemo';
import DxAccordiondemo from './views/dx-accordiondemo';
import DxMusclegroup from './views/dx-musclegroup';

import DxRegisterform from './views/dx-registerform';


//WEBSOCKET
import WsTestsocket from './views/ws-testsocket';
import WsChat from './views/ws-chat';

//DESIGN
import DesignDashboard from './views/design-dashboard';
import DesignMember from './views/design-member';
import DesignCourseplan from './views/design-courseplan';
import DesignExerciseplan from './views/design-exerciseplan';
import DesignTasklist from './views/design-tasklist';
import DesignMessagelist from './views/design-messagelist';
import DesignContractlist from './views/design-contractlist';
import DesignInvoicelist from './views/design-invoicelist';
import DesignShopsale from './views/design-shopsale';
import DesignStatistic from './views/design-statistic';


function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  routes: [

			//DEFAULT ROUTES
    {
      path: "/home",
      name: "home-page",
      meta: {
        requiresAuth: true,
        layout: simpleLayout
      },
      component: Home
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Anmelden"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Passwort zurücksetzen",
        description: "Bitte geben Sie die E-Mail-Adresse ein, die Sie bei der Registrierung verwendet haben. Wir senden Ihnen dann einen Link zum Zurücksetzen Ihres Passworts per E-Mail zu."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Registrieren"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Passwort ändern"
      },
      component: loadView("change-password-form")
    },
    
    {
      path: "/renew-abo/:recoveryCode",
      name: "renew-abo",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Abo verlängern",
        //description: "Bitte klicken Sie…"
      },
      component: loadView("renew-abo-form")
    },
    
    {
      path: "/notrenew-abo/:recoveryCode",
      name: "notrenew-abo",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Abo nicht verlängern",
        //description: "Bitte klicken Sie…"
      },
      component: loadView("notrenew-abo-form")
    },
    
    
    
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }, 
    {
      path: "/about",
      name: "about",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: About
    }, 
    
				//SHOP OPEN
				
				//00-home
				{
				path: "/shop",
				name: "shop",
				meta: {
				requiresAuth: false,
				layout: shopLayout
				},
				component: ShopHome
				}, 
    
				//01-products
				{
				path: "/shopproduct",
				name: "shopproduct",
				meta: {
				requiresAuth: false,
				layout: shopLayout
				},
				component: ShopProduct
				}, 
				//02-cart
				{
				path: "/shopcart",
				name: "shoppcart",
				meta: {
				requiresAuth: false,
				layout: shopLayout
				},
				component: ShopCart
				}, 
				//03-checkout
				{
				path: "/shopcheckout",
				name: "shoppcheckout",
				meta: {
				requiresAuth: false,
				layout: shopLayout
				},
				component: ShopCheckout
				}, 
				//04-purchase
				{
				path: "/shoppurchase",
				name: "shopppurchase",
				meta: {
				requiresAuth: false,
				layout: shopLayout
				},
				component: ShopPurchase
				}, 
    
    
				//CUSTOMER APP
				
				//00-dashboard
				{
				path: "/dashboard",
				name: "dashboard",
				meta: {
				requiresAuth: true,
				layout: appLayout
				},
				component: CustomerDashboard
				}, 
				//01-checkin
				{
				path: "/checkin",
				name: "checkin",
				meta: {
				requiresAuth: true,
				layout: appLayout
				},
				component: CustomerCheckin
				}, 
				//02-trainingplan
				{
				path: "/trainingplan",
				name: "trainingplan",
				meta: {
				requiresAuth: true,
				layout: appLayout
				},
				component: CustomerTrainingplan
				}, 
				//04-contract
				{
				path: "/contract",
				name: "contract",
				meta: {
				requiresAuth: true,
				layout: appLayout
				},
				component: CustomerContract
				}, 
				//05-shop
				{
				path: "/myshop",
				name: "myshop",
				meta: {
				requiresAuth: true,
				layout: appLayout
				},
				component: CustomerShop
				}, 
				//03-measurement
				{
				path: "/measurement",
				name: "measurement",
				meta: {
				requiresAuth: true,
				layout: appLayout
				},
				component: CustomerMeasurement
				}, 
				//04-profile
				{
				path: "/myprofile",
				name: "myprofile",
				meta: {
				requiresAuth: true,
				layout: appLayout
				},
				component: CustomerProfile
				}, 
				//05-more
				{
				path: "/more",
				name: "more",
				meta: {
				requiresAuth: true,
				layout: appLayout
				},
				component: CustomerMore
				}, 

				//STUDIO APP
				
				//00-dev
				{
      path: "/sap-memberinfinite",
      name: "sap-memberinfinite",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MemberInfinite
    }, 

				{
      path: "/sap-contractinfinite",
      name: "sap-contractinfinite",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractInfinite
    }, 

				{
      path: "/sap-invoiceinfinite",
      name: "sap-invoiceinfinite",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: InvoiceInfinite
    }, 
				
				//00-member
				{
      path: "/sap-dashboard",
      name: "sap-dashboard",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MemberDashboard
    }, 
    {
      path: "/sap-memberactive",
      name: "sap-memberactive",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MemberActive
    }, 
    {
      path: "/sap-memberinactive",
      name: "sap-memberinactive",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MemberInactive
    }, 
    {
      path: "/sap-memberblocked",
      name: "sap-memberblocked",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MemberBlocked
    }, 
    {
      path: "/sap-memberinterested",
      name: "sap-memberinterested",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MemberInterested
    }, 
    {
      path: "/sap-memberall",
      name: "sap-memberall",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MemberAll
    }, 
    {
      path: "/sap-membercheckin",
      name: "sap-membercheckin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MemberCheckin
    }, 
				//01-scheduler
    {
      path: "/sap-scheduler",
      name: "sap-scheduler",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Scheduler
    }, 
				//02-course
    {
      path: "/sap-courseplan",
      name: "sap-courseplan",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: CoursePlan
    }, 
				//03-exercise
    {
      path: "/sap-exerciseplan",
      name: "sap-exerciseplan",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ExercisePlan
    }, 
				//04-task
    {
      path: "/sap-tasklist",
      name: "sap-tasklist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: TaskList
    }, 
				//05-message
    {
      path: "/sap-messagelist",
      name: "sap-messagelist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MessageList
    }, 
				//06-contract
    {
      path: "/sap-contractlist",
      name: "sap-contractlist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractList
    }, 
    {
      path: "/sap-contractlist-active",
      name: "sap-contractlist-active",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListActive
    }, 
    {
      path: "/sap-contractlist-inactive",
      name: "sap-contractlist-inactive",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListInactive
    }, 
    {
      path: "/sap-contractlist-extended",
      name: "sap-contractlist-extended",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListExtended
    }, 
    {
      path: "/sap-contractlist-notextended",
      name: "sap-contractlist-notextended",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListNotextended
    }, 
    {
      path: "/sap-contractlist-reminder",
      name: "sap-contractlist-reminder",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListReminder
    }, 
    {
      path: "/sap-contractlist-mailsent",
      name: "sap-contractlist-mailsent",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListMailsent
    }, 
    {
      path: "/sap-contractlist-abo",
      name: "sap-contractlist-abo",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListAbo
    }, 
    {
      path: "/sap-contractlist-signature",
      name: "sap-contractlist-signature",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListSignature
    }, 
    {
      path: "/sap-contractlist-invoice",
      name: "sap-contractlist-invoice",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListInvoice
    }, 
    {
      path: "/sap-contractlist-even",
      name: "sap-contractlist-even",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListEven
    }, 
    {
      path: "/sap-contractlist-odd",
      name: "sap-contractlist-odd",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractListOdd
    }, 
    {
      path: "/sap-contractreminder",
      name: "sap-contractreminder",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ContractReminder
    }, 
    
				//07-invoice
    {
      path: "/sap-invoicelist",
      name: "sap-invoicelist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: InvoiceList
    }, 
    {
      path: "/sap-invoicelist-all",
      name: "sap-invoicelist-all",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: InvoiceListAll
    }, 
    {
      path: "/sap-invoicelist-due",
      name: "sap-invoicelist-due",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: InvoiceListDue
    }, 
    {
      path: "/sap-invoicelist-paid",
      name: "sap-invoicelist-paid",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: InvoiceListPaid
    }, 
				//08-shop
    {
      path: "/sap-shopsale",
      name: "sap-shopsale",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ShopSale
    }, 
				//09-statistic
    {
      path: "/sap-statistic",
      name: "sap-statistic",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Statistic
    }, 
/*
    {
      path: "/sap-statistic1",
      name: "sap-statistic1",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Statistic1
    }, 
    {
      path: "/sap-statistic2",
      name: "sap-statistic2",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Statistic2
    }, 
    {
      path: "/sap-statistic3",
      name: "sap-statistic3",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Statistic3
    }, 
    {
      path: "/sap-statistic4",
      name: "sap-statistic4",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Statistic4
    }, 
*/
				//90-setting
    {
      path: "/sap-settingcompany",
      name: "sap-settingcompany",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SettingCompany
    }, 
    {
      path: "/sap-settingmodule",
      name: "sap-settingmodule",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SettingModule
    }, 
    {
      path: "/sap-settingemployee",
      name: "sap-settingemployee",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SettingEmployee
    }, 
    {
      path: "/sap-settingemployee-inactive",
      name: "sap-settingemployee-inactive",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SettingEmployeeInactive

    }, 
    {
      path: "/sap-settingcourse",
      name: "sap-settingcourse",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SettingCourse
    }, 
    {
      path: "/sap-settingexercise",
      name: "sap-settingexercise",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SettingExercise
    }, 
    {
      path: "/sap-settinghealth",
      name: "sap-settinghealth",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SettingHealth
    }, 
    {
      path: "/sap-settingaboitem",
      name: "sap-settingaboitem",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SettingAboItem
    }, 
    {
      path: "/sap-settingshopitem",
      name: "sap-settingshopitem",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SettingShopItem
    }, 
				
        
				//ROUTES OLD
    
    {
      path: "/members-page",
      name: "members-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Members
    }, 
    {
      path: "/contracts-page",
      name: "contracts-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Contracts
    },
    {
      path: "/checkin-page",
      name: "checkin-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Checkin
    },
    {
      path: "/calendar-page",
      name: "calendar-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Calendar
    },
    {
      path: "/message-page",
      name: "message-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Message
    },
    {
      path: "/video-page",
      name: "video-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Video
    },
    {
      path: "/training-page",
      name: "training-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Training
    },
    {
      path: "/shop-page",
      name: "shop-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Shop
    },
    {
      path: "/task-page",
      name: "task-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Task
    },
    {
      path: "/analytic-page",
      name: "analytic-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Analytic
    },
    {
      path: "/setting-page",
      name: "setting-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Setting
    },
    
    //FOUNDATION
    {
      path: "/index",
      name: "index",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Index
    },
    {
      path: "/grid",
      name: "grid",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Grid
    },
    {
      path: "/typography",
      name: "typography",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Typography
    },
    {
      path: "/button",
      name: "button",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Button
    },
    {
      path: "/accordion",
      name: "accordion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Accordion
    },
    {
      path: "/tabs",
      name: "tabs",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tabs
    },
    {
      path: "/reveal",
      name: "reveal",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Reveal
    },
    {
      path: "/tooltip",
      name: "tooltip",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tooltip
    },
    {
      path: "/dropdownmenu",
      name: "dropdownmenu",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Dropdownmenu
    },
    {
      path: "/drilldownmenu",
      name: "drilldownmenu",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Drilldownmenu
    },
    {
      path: "/accordionmenu",
      name: "accordionmenu",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Accordionmenu
    },
    
    //DEVEXTREME
     {
      path: "/dx-jsonreadonly",
      name: "dx-jsonreadonly",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxJsonreadonly
    },
     {
      path: "/dx-jsonstore",
      name: "dx-jsonstore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxJsonstore
    },
   {
      path: "/dx-customstore",
      name: "dx-customstore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxCustomstore
    },
   {
      path: "/dx-memberstore",
      name: "dx-memberstore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxMemberstore
    },
   {
      path: "/dx-employeestore",
      name: "dx-employeestore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxEmployeestore
    },
   {
      path: "/dx-memberpopup",
      name: "dx-memberpopup",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxMemberpopup
    },
   {
      path: "/dx-memberaccordion",
      name: "dx-memberaccordion",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxMemberaccordion
    },
   {
      path: "/dx-memberlist",
      name: "dx-memberlist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxMemberlist
    },
   {
      path: "/dx-taskstore",
      name: "dx-taskstore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxTaskstore
    },
   {
      path: "/dx-contractstore",
      name: "dx-contractstore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxContractstore
    },
   {
      path: "/dx-invoicestore",
      name: "dx-invoicestore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxInvoicestore
    },
   {
      path: "/dx-coursestore",
      name: "dx-coursestore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxCoursestore
    },
   {
      path: "/dx-measurementstore",
      name: "dx-measurementstore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxMeasurementstore
    },
   {
      path: "/dx-exercisestore",
      name: "dx-exercisestore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxExercisestore
    },
   {
      path: "/dx-exercisegroupstore",
      name: "dx-exercisegroupstore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxExercisegroupstore
    },
   {
      path: "/dx-exerciseplanstore",
      name: "dx-exerciseplanstore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxExerciseplanstore
    },
   {
      path: "/dx-messagestore",
      name: "dx-messagestore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxMessagestore
    },
   {
      path: "/dx-abostore",
      name: "dx-abostore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxAbostore
    },
   {
      path: "/dx-shopstore",
      name: "dx-shopstore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxShopstore
    },
   {
      path: "/dx-teststore",
      name: "dx-teststore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxTeststore
    },
   {
      path: "/dx-testform",
      name: "dx-testform",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxTestform
    },
   {
      path: "/dx-demoform",
      name: "dx-demoform",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxDemoform
    },
   {
      path: "/dx-popupdemo",
      name: "dx-popupdemo",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxPopupdemo
    },
   {
      path: "/dx-accordiondemo",
      name: "dx-accordiondemo",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxAccordiondemo
    },
    {
      path: "/dx-musclegroup",
      name: "dx-musclegroup",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: DxMusclegroup
    },
    {
      path: "/dx-registerform",
      name: "dx-registerform",
      meta: {
        requiresAuth: false,
        layout: registerLayout,
        title: "Sign In"
      },
      component: DxRegisterform
    },
				
				//WEBSOCKETS
				
    {
      path: "/ws-chat",
      name: "ws-chat",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Socket Chat"
      },
      component: WsChat
    },
    {
      path: "/ws-testsocket",
      name: "ws-testsocket",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Socket Test"
      },
      component: WsTestsocket
    },

				//DESIGN
    {
      path: "/design-dashboard",
      name: "design-dashboard",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Dashboard"
      },
      component: DesignDashboard
    },
    {
      path: "/design-member",
      name: "design-member",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Mitglieder"
      },
      component: DesignMember
    },
    {
      path: "/design-courseplan",
      name: "design-courseplan",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Kurspläne"
      },
      component: DesignCourseplan
    },
    {
      path: "/design-exerciseplan",
      name: "design-exerciseplan",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Trainingspläne"
      },
      component: DesignExerciseplan
    },
    {
      path: "/design-tasklist",
      name: "design-tasklist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Aufgaben"
      },
      component: DesignTasklist
    },
    {
      path: "/design-messagelist",
      name: "design-messagelist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Nachrichten"
      },
      component: DesignMessagelist
    },
    {
      path: "/design-contractlist",
      name: "design-contractlist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Verträge"
      },
      component: DesignContractlist
    },
    {
      path: "/design-invoicelist",
      name: "design-invoicelist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Rechnungen"
      },
      component: DesignInvoicelist
    },
    {
      path: "/design-shopsale",
      name: "design-shopsale",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Verkauf"
      },
      component: DesignShopsale
    },
    {
      path: "/design-statistic",
      name: "design-statistic",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        title: "Statistik"
      },
      component: DesignStatistic
    },

    
  ],
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {

  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
