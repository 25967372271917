<template>
<svg version="1.1" id="logo-fightindustry" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 1058.5 234.7" style="enable-background:new 0 0 1058.5 234.7;" xml:space="preserve">
<path class="st4" d="M207.6,54.3h-55.7L89.5,178.9l28,55.8L207.6,54.3z"/>
<path class="st0" d="M210.9,47.5L234.8,0H0l85.5,170.9l27.8-55.8l-11-22h22.1l19.2-38.8H82.9l-3.4-6.8H210.9z"/>
<path class="st1" d="M204.8,56.2h-51.3L119.6,124l-1.8,3.5v102.8L204.8,56.2z"/>
<path class="st2" d="M210.1,45.3L232,1.8H117.8v43.5H210.1z"/>
<path class="st2" d="M140.8,56.2h-23v34.8h5.7L140.8,56.2z"/>
<path class="st3" d="M268.9,82.4c0-1,0.8-1.9,1.9-1.9h40c1.1,0,1.9,0.9,1.9,1.9V88c0,1-0.8,1.9-1.9,1.9h-31.6V113h26.7
c1,0,1.9,0.9,1.9,1.9v5.5c0,1-0.9,1.9-1.9,1.9h-26.7v26.3c0,1-0.9,1.9-1.9,1.9h-6.5c-1.1,0-1.9-0.9-1.9-1.9V82.4z"/>
<path class="st3" d="M325.5,82.4c0-1,0.9-1.9,1.9-1.9h6.6c1,0,1.9,0.9,1.9,1.9v66.2c0,1-0.9,1.9-1.9,1.9h-6.6c-1,0-1.9-0.9-1.9-1.9
V82.4z"/>
<path class="st3" d="M386.2,79.4c9.6,0,17.5,3.5,24.3,9.3c0.8,0.8,0.9,2,0.1,2.8c-1.5,1.6-3,3.1-4.5,4.7c-0.8,0.9-1.6,0.8-2.6-0.1
c-4.7-4.1-10.9-6.8-17-6.8c-14.2,0-25.1,12-25.1,26c0,13.9,10.9,25.9,25.1,25.9c8.3,0,14-3,15-3.4v-11.2h-9.8
c-1.1,0-1.9-0.8-1.9-1.8v-5.8c0-1.1,0.8-1.9,1.9-1.9h18.2c1,0,1.8,0.9,1.8,1.9c0,8,0.1,16.2,0.1,24.2c0,0.5-0.4,1.3-0.8,1.6
c0,0-10.3,6.6-24.8,6.6c-19.9,0-36-15.9-36-35.9S366.3,79.4,386.2,79.4z"/>
<path class="st3" d="M427.5,82.4c0-1,0.9-1.9,1.9-1.9h6.6c1.1,0,1.9,0.9,1.9,1.9v27.9h36.6V82.4c0-1,0.8-1.9,1.9-1.9h6.6
c1,0,1.9,0.9,1.9,1.9v66.2c0,1-0.9,1.9-1.9,1.9h-6.6c-1.1,0-1.9-0.9-1.9-1.9v-28.9h-36.6v28.9c0,1-0.8,1.9-1.9,1.9h-6.6
c-1,0-1.9-0.9-1.9-1.9V82.4z"/>
<path class="st3" d="M513.9,89.9h-16.3c-1.1,0-1.9-0.9-1.9-1.9v-5.6c0-1,0.8-1.9,1.9-1.9h43c1.1,0,1.9,0.9,1.9,1.9V88
c0,1-0.8,1.9-1.9,1.9h-16.3v58.7c0,1-0.9,1.9-1.9,1.9h-6.6c-1,0-1.9-0.9-1.9-1.9V89.9z"/>
<path class="st3" d="M575.3,82.4c0-1,0.9-1.9,1.9-1.9h6.6c1,0,1.9,0.9,1.9,1.9v66.2c0,1-0.9,1.9-1.9,1.9h-6.6c-1,0-1.9-0.9-1.9-1.9
V82.4z"/>
<path class="st3" d="M605.1,81.3c0-1,0.9-1.8,1.9-1.8h2.5l43.4,50.2h0.1V82.4c0-1,0.8-1.9,1.9-1.9h6.4c1,0,1.9,0.9,1.9,1.9v67.3
c0,1-0.9,1.8-1.9,1.8h-2.6l-43.4-51.6h-0.1v48.7c0,1-0.8,1.9-1.9,1.9H607c-1,0-1.9-0.9-1.9-1.9V81.3z"/>
<path class="st3" d="M682.6,82.4c0-1,0.8-1.9,1.8-1.9h23c19.3,0,35.1,15.7,35.1,34.9c0,19.4-15.8,35.1-35.1,35.1h-23
c-1,0-1.8-0.9-1.8-1.9V82.4z M706,141c14.7,0,25.4-10.8,25.4-25.6c0-14.7-10.7-25.4-25.4-25.4h-13.2v51H706z"/>
<path class="st3" d="M756,82.4c0-1,0.9-1.9,1.9-1.9h6.7c1.1,0,1.9,0.9,1.9,1.9v41.3c0,10,6.4,17.8,16.6,17.8
c10.3,0,16.8-7.7,16.8-17.6V82.4c0-1,0.8-1.9,1.9-1.9h6.7c1,0,1.9,0.9,1.9,1.9v42c0,15.2-11.5,27.1-27.3,27.1
c-15.7,0-27.1-11.9-27.1-27.1V82.4z"/>
<path class="st3" d="M824.4,140.4c0.9-1.3,1.7-2.8,2.6-4.1c0.9-1.3,2.3-1.7,3.4-0.8c0.6,0.5,8.3,6.9,16,6.9
c6.9,0,11.3-4.2,11.3-9.3c0-6-5.2-9.8-15.1-13.9c-10.2-4.3-18.2-9.6-18.2-21.2c0-7.8,6-18.5,21.9-18.5c10,0,17.5,5.2,18.5,5.9
c0.8,0.5,1.6,1.9,0.6,3.4c-0.8,1.2-1.7,2.6-2.5,3.8c-0.8,1.3-2.1,1.9-3.5,1c-0.7-0.4-7.7-5-13.5-5c-8.4,0-11.3,5.3-11.3,9
c0,5.7,4.4,9.2,12.7,12.6c11.6,4.7,21.5,10.2,21.5,22.4c0,10.4-9.3,18.9-22.3,18.9c-12.2,0-19.9-6.4-21.5-7.9
C824.1,142.8,823.4,142.1,824.4,140.4z"/>
<path class="st3" d="M892.2,89.9h-16.3c-1.1,0-1.9-0.9-1.9-1.9v-5.6c0-1,0.8-1.9,1.9-1.9h43c1.1,0,1.9,0.9,1.9,1.9V88
c0,1-0.8,1.9-1.9,1.9h-16.3v58.7c0,1-0.9,1.9-1.9,1.9h-6.6c-1,0-1.9-0.9-1.9-1.9V89.9z"/>
<path class="st3" d="M931.6,82.4c0-1,0.8-1.9,1.9-1.9h27.2c12,0,21.8,9.5,21.8,21.4c0,9.2-6.1,16.8-14.8,20.3l13.7,25.4
c0.7,1.3,0,2.9-1.7,2.9h-7.8c-0.8,0-1.4-0.5-1.6-0.9L957,123.1h-15.1v25.5c0,1-0.9,1.9-1.9,1.9h-6.5c-1.1,0-1.9-0.9-1.9-1.9V82.4z
M959.9,114.5c6.5,0,12.2-5.5,12.2-12.4c0-6.5-5.7-11.9-12.2-11.9h-17.8v24.3H959.9z"/>
<path class="st3" d="M1011.8,116.8l-23.4-33.4c-0.8-1.3,0-2.9,1.6-2.9h7.4c0.8,0,1.3,0.5,1.6,0.9l18.1,25.5l18.1-25.5
c0.3-0.4,0.9-0.9,1.6-0.9h7.5c1.6,0,2.4,1.6,1.6,2.9l-23.7,33.3v31.9c0,1-0.9,1.9-1.9,1.9h-6.6c-1.1,0-1.9-0.9-1.9-1.9V116.8z"/>
</svg>
</template>

