<template>
<svg version="1.1" id="logo-fitwork" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 400 64" style="enable-background:new 0 0 400 64;" xml:space="preserve">
<path class="st0" d="M211.8,17.8c-0.3-0.1-0.7-0.1-1-0.1c-3.7,0.3-7.3,0-11,0c-0.2,0-0.5,0-0.7,0c-0.5,0.1-1,0.1-1,0.7
c0,0.6,0.5,0.7,0.9,0.8c0.4,0.1,0.8,0,1.2,0.1c1.3,0.2,1.8,0.7,1.6,2c-0.1,0.9-0.3,1.9-0.6,2.8c-2.5,8.2-5,16.4-7.5,24.6
c-0.1,0.3,0,0.8-0.6,0.9c-0.1-0.2-0.2-0.4-0.3-0.7c-3.6-8.6-7.2-17.2-10.8-25.8c-0.6-1.5-1.2-3-2.1-4.4c-0.7-1-1.3-1-2.1-0.1
c-0.5,0.6-0.8,1.4-1.1,2.1c-3.5,9-7,18-10.5,27c-0.3,0.7-0.4,1.5-1,2.4c-0.3-0.7-0.4-1.2-0.6-1.6c-1.6-5.2-3.2-10.3-4.8-15.5
c-1.2-3.9-2.4-7.8-3.3-11.8c-0.3-1.3,0-1.7,1.4-1.9c0.7-0.1,1.7,0.1,1.7-0.9c-0.1-1.1-1.1-0.7-1.8-0.7c-2.5,0-5,0.2-7.5,0.1
c-2.2-0.1-4.3-0.1-6.5-0.1c-0.6,0-1.3,0-1.3,0.8c0,0.7,0.7,0.8,1.2,0.8c1.9,0,3,1,3.5,2.8c0.2,0.7,0.5,1.4,0.8,2.1
c3.8,11.1,7.6,22.1,11.4,33.2c0.6,1.8,1.2,3.5,1.9,5.3c0.2,0.6,0.7,1.2,1.4,1.3c0.8,0,1.2-0.5,1.5-1.1c0.7-1.5,1.4-2.9,2.1-4.4
c3.5-8.3,6.9-16.7,10.1-25.1c0.1-0.3,0.2-0.9,0.6-0.9c0.5,0,0.5,0.5,0.7,0.9c2.4,5.6,4.7,11.1,7.1,16.7c1.7,3.9,3.4,7.9,5.1,11.8
c0.4,1,0.8,2.1,2.1,2.1c1.3,0,1.4-1.3,1.9-2.3c0.1-0.1,0.1-0.2,0.1-0.4c2.4-7.1,4.8-14.2,7.2-21.3c2-5.7,4-11.4,6.1-17.1
c0.7-2,1.7-3.8,4.3-3.5c0.1,0,0.2,0,0.4-0.1c0.4-0.1,0.7-0.4,0.6-0.8C212.5,18.1,212.2,17.9,211.8,17.8z"/>
<path class="st0" d="M262.9,19.3c-6-2.5-12.2-2.8-18.5-1.9c-5.5,0.8-10.4,3.1-14.1,7.3c-4.8,5.3-6.8,11.6-5.9,18.7
c0.9,7,4.2,12.6,10.2,16.5c4.5,2.9,9.5,4.1,14.8,3.9c1.7,0,3.4,0,5.1-0.3c7-1.2,12.8-4.3,16.8-10.2c3.2-4.6,4.3-9.8,4.1-15.4
C275.4,29.8,270.6,22.5,262.9,19.3z M265.3,49.3c-2.4,9.8-10.5,13.2-19.2,10.1c-6.8-2.4-10.1-7.8-11.7-14.5
c-0.5-2.1-0.6-4.3-0.6-6.5c-0.1-3,0.3-6,1.3-8.8c2.4-6.9,8.2-9.6,14.8-9.1c8,0.5,14.2,7,15.8,15.8C266.5,40.6,266.3,45,265.3,49.3z"
/>
<path class="st0" d="M339.9,61.7c-0.7-0.1-1.4-0.2-2.1-0.3c-3.2-0.7-5.4-3-7.5-5.3c-3.8-4.1-7.2-8.5-10.8-12.8
c-1-1.3-1.1-1.3,0.2-2.4c2.3-2,4.5-4.1,5.9-6.9c3-5.9,0.6-12.4-5.6-14.8c-2.5-1-5.1-1.3-7.7-1.3c-3.2-0.1-6.5,0.1-9.7,0.2
c-3.1,0.1-6.2-0.1-9.4-0.2c-0.7,0-1.9-0.3-2,0.7c-0.1,1.2,1.2,0.8,2,0.9c3.1,0.2,4,1.1,4,4.1c0,10,0,20,0,30c0,1.8,0,3.6-0.2,5.4
c-0.3,2-0.9,2.6-2.9,2.7c-0.7,0-1.9-0.2-1.8,0.9c0,1.1,1.2,0.8,1.9,0.7c4.5-0.1,9.1-0.1,13.6,0c1,0,2.1,0.3,3.1,0.1
c0.5-0.1,1-0.2,1-0.8c0-0.7-0.5-0.8-1-0.9c-0.6-0.1-1.2-0.1-1.9-0.1c-2.2-0.1-2.9-0.9-3.1-3.1c-0.2-2.5-0.3-5.1-0.3-7.6
c0-1.7,0-3.4,0-5.1c0-0.7,0.3-1.1,1-1.1c1.5,0,2.9,0,4.4,0c0.8,0,1.4,0.3,1.9,1c0.9,1.4,1.9,2.7,2.9,4.1c2,2.9,4.1,5.8,6.4,8.5
c1.8,2.1,3.8,4,6.7,4.6c3.6,0.7,7.3,0.2,11,0.3c0.5,0,0.8-0.2,0.9-0.7C340.6,61.9,340.3,61.8,339.9,61.7z M311.3,41.3
c-1.4-0.1-2.7,0-4.1-0.2c-1.2-0.2-1.7-0.7-1.7-2c0.1-2.7,0-5.3,0-8c0,0,0,0,0,0c0-2.7,0-5.5,0-8.2c0-1,0.3-1.4,1.3-1.5
c2.9-0.4,5.7-0.1,8.1,1.6c2.1,1.5,3.2,3.6,3.5,6.1c0.5,3.4,0.3,6.7-1.9,9.5C315.3,40.3,313.6,41.4,311.3,41.3z"/>
<path class="st0" d="M398.7,61.6c-2.6-0.1-4.8-1.2-6.8-2.8c-2.4-1.8-4.6-3.8-6.7-6c-4.7-4.8-9.2-9.7-13.6-14.6
c-1.1-1.2-1.2-1.3,0-2.4c4.1-3.9,8.2-7.9,12.3-11.8c2.3-2.2,4.9-4.1,8.2-4.5c0.6-0.1,1.2-0.1,1.7-0.2c0.3-0.1,0.6-0.3,0.7-0.6
c0-0.5-0.3-0.7-0.7-0.8c-0.4-0.1-0.8-0.1-1.2-0.1c-2.4,0.1-4.8,0.2-7.2,0.2c-1.8,0-3.6-0.1-5.4-0.2c-0.5,0-1.1-0.1-1.3,0.5
c-0.1,0.5,0.4,0.8,0.8,1c1,0.6,1.1,1.3,0.4,2.1c-0.3,0.5-0.7,1-1.1,1.4c-3,3.5-6.4,6.5-9.6,9.9c-1.5,1.6-3.1,3.2-4.7,4.8
c-0.1-0.1-0.2-0.1-0.3-0.2c0-4.7,0-9.5,0-14.2c0-2.8,0.7-3.4,3.4-3.7c0.6-0.1,1.4,0,1.4-0.9c0-0.9-0.9-0.7-1.4-0.7
c-0.2,0-0.5,0-0.7,0c-2.7,0.1-5.3,0.3-8,0.2c-2.5-0.1-4.9-0.2-7.4-0.2c-0.4,0-0.8,0-1.1,0.1c-0.4,0.1-0.6,0.3-0.6,0.7
c0,0.4,0.2,0.7,0.6,0.8c0.6,0.1,1.2,0.1,1.9,0.1c2.4,0.2,3.2,0.9,3.5,3.2c0.1,0.7,0.1,1.3,0.1,2c0,9.5,0,19.1,0,28.6
c0,1.7-0.1,3.3-0.3,5c-0.3,2.8-0.7,3.2-3.5,3.3c-0.2,0-0.4,0-0.6,0c-0.5,0.1-0.9,0.3-0.9,0.9c0.1,0.6,0.5,0.7,1,0.7
c0.7,0,1.5,0,2.2,0c2.5,0.1,5.1-0.3,7.6-0.1c2.6,0.2,5.2,0.1,7.9,0.1c0.5,0,1-0.1,1-0.7c0-0.7-0.5-0.8-1-0.9c-0.5-0.1-1-0.1-1.5-0.1
c-2.6-0.1-3.5-0.8-3.6-3.4c-0.1-5.7,0-11.5,0-17.3c1.1,1,2,2.2,2.9,3.4c4.1,4.8,8.2,9.6,12.6,14.2c2.6,2.7,5.6,4.7,9.5,4.8
c3.1,0.1,6.2,0,9.4,0c0.5,0,1.2,0,1.2-0.7C400,61.6,399.3,61.6,398.7,61.6z"/>
<path class="st0" d="M33.6,9.5c-1.5,0.4-2.9,0.5-4.5,0.5c-3.8,0-7.6,0-11.4,0C13,10,8.3,10.2,3.5,9.8C2.6,9.7,1.7,9.7,0.8,9.9
C0.4,10,0,10.2,0,10.7c0,0.5,0.4,0.7,0.9,0.7c0.9,0.1,1.8,0.1,2.7,0.2c1.7,0.2,2.8,1.2,3.1,2.8c0.1,1.1,0.3,2.2,0.3,3.2
c0,11.5,0,23,0,34.5c0,2.4,0,4.8-0.7,7.2c-0.4,1.6-1,2-2.6,2.1c-0.6,0-1.2,0-1.7,0.1c-0.4,0.1-0.8,0.2-0.8,0.7
c0,0.5,0.3,0.8,0.7,0.9c0.8,0.2,1.7,0.1,2.5,0.1c2.9-0.1,5.7-0.3,8.6-0.2c3.1,0.1,6.2,0.2,9.4,0.2c0.6,0,1.5,0.1,1.5-0.9
c-0.1-0.8-0.9-0.8-1.5-0.8c-1-0.1-1.9-0.1-2.9-0.2c-1.9-0.3-2.6-0.9-2.8-2.9c-0.8-7-0.4-14-0.5-21c0-0.7,0.4-0.9,1-0.9
c3.1,0,6.3-0.1,9.4,0.1c2.4,0.1,3.8,1.5,4.1,3.7c0.1,0.7-0.2,1.9,1,1.8c1-0.1,0.8-1.2,0.8-2c0.1-2.8,0.3-5.6,0.7-8.3
c0.1-0.4,0.2-0.9-0.3-1.1c-0.5-0.2-0.8,0.1-1.1,0.4c-0.7,0.9-1.6,1.2-2.7,1.3c-4,0.2-8,0.1-12,0.1c-0.8,0-0.8-0.6-0.8-1.1
c0-2.7,0-5.4,0-8.1c0-2.7,0-5.3,0-8c0-1,0.3-1.4,1.3-1.4c3.3,0.1,6.6-0.1,9.9,0.1c2.9,0.2,4.9,0.8,4.9,4.5c0,0.6,0.1,1.3,0.9,1.3
c0.8,0,1-0.7,1-1.3c0.2-2.7,0.2-5.5,0.7-8.2C35,9.5,34.4,9.3,33.6,9.5z"/>
<path class="st0" d="M128.9,17.5c-1.7,0.2-3.5,0.4-5.2,0.4c-8.3,0-16.7,0.1-25,0c-2.6,0-5.3,0-7.8-0.8c-1-0.3-1.4,0.1-1.6,1
c-0.4,2.3-0.9,4.6-1.3,6.9c-0.1,0.6-0.2,1.3,0.6,1.5c0.7,0.2,0.9-0.5,1.1-1c0.1-0.1,0.2-0.3,0.2-0.5c0.8-2,2.3-2.8,4.4-3
c3.3-0.3,6.6-0.1,9.9-0.2c1.1-0.1,1.3,0.4,1.3,1.4c0,10.4,0,20.8-0.1,31.2c0,1.7-0.1,3.5-0.5,5.2c-0.3,1.1-1,1.8-2.2,1.8
c-0.4,0-0.8,0-1.1,0c-0.6,0.1-1.3,0.1-1.3,0.9c0,0.8,0.7,0.8,1.3,0.9c0.5,0,1,0,1.5,0c4.9-0.4,9.8-0.1,14.7-0.1c0.7,0,1.4,0.1,2.1,0
c0.4-0.1,0.8-0.2,0.8-0.7c0-0.5-0.4-0.7-0.8-0.8c-0.8-0.1-1.7-0.1-2.5-0.2c-2-0.1-2.7-0.6-2.9-2.5c-0.3-1.8-0.4-3.6-0.4-5.3
c-0.1-4.9,0-9.8,0-14.7c0-5.2,0-10.5,0-15.7c0-1,0.3-1.3,1.3-1.3c2.3,0.1,4.6,0,6.9,0.1c1.2,0,2.3,0.2,3.4,0.5
c1.7,0.5,3,1.3,3.1,3.3c0,0.2,0.1,0.5,0.2,0.7c0.2,0.3,0.4,0.7,0.8,0.6c0.4-0.1,0.5-0.4,0.6-0.7c0.1-0.4,0.1-0.7,0.1-1.1
c0-2.1,0-4.2,0-6.2C130.6,17.5,130.4,17.4,128.9,17.5z"/>
<path class="st0" d="M72.6,61.6c-0.8-0.1-1.7-0.1-2.5-0.1c-2-0.2-2.8-0.9-3-3c-0.1-1.6-0.3-3.2-0.3-4.7c0-9.4,0-18.8,0-28.2
c0-1,0.1-2.1,0.2-3.1c0.1-2.3,1.3-2.8,3.2-3.1c0.7-0.1,1.8,0.2,1.8-0.8c0-1.1-1.2-0.8-1.9-0.8c-4.2-0.1-8.5,0.5-12.7,0
c-0.9,0-1.8,0-2.7,0c-0.6,0-1.5-0.2-1.6,0.8c0,1,0.9,0.8,1.5,0.9c0.1,0,0.2,0,0.4,0c2.2,0.2,3.3,1.2,3.3,3.4
c-0.1,11.9,0.3,23.7-0.2,35.6c-0.1,2.4-0.7,3-3.1,3.1c-0.7,0-1.8-0.1-1.7,1c0.1,1,1.1,0.7,1.7,0.7c4.8-0.1,9.6-0.1,14.5,0
c1.1,0,2.1,0.3,3.2,0.1c0.5-0.1,0.9-0.2,0.9-0.8C73.5,61.8,73,61.6,72.6,61.6z"/>
<path class="st1" d="M62.6,0c-3.4-0.1-6.2,2.7-6.3,6.1c-0.1,3.4,2.7,6.3,6.1,6.3c3.4,0,6.2-2.6,6.3-6.1C68.7,2.9,66,0.1,62.6,0z"/>
</svg>
</template>

