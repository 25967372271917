<template>
<svg version="1.1" id="logo-lifefitness24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
y="0px" viewBox="0 0 286.3 40.9" style="enable-background:new 0 0 286.3 40.9;" xml:space="preserve">
<path class="st0" d="M0,31.3c2.1-1.4,4.6-2.2,7-3c1.4-0.5,2.5-2,3.6-3c5.7-5.1,11.7-10,18-14.3c2-1.3,4-2.6,6-3.9
c2.7-1.8,5.5-1.4,8.2-0.4c0.8,0.3,0.9,0.9,0.3,1.4c-2.6,1.8-4.9,4.4-8.6,4.2c-0.5,0-1.2,0.3-1.7,0.6c-3.2,2.2-6.5,4.3-9.6,6.6
c-3.1,2.2-6,4.7-9,7c-0.1,0.1-0.3,0.3-0.5,0.6h15.3C29,26.7,29,26.3,28.8,26c-0.4-1-0.4-2.1,0.5-2.9c2.1-2,4-3.9,6.1-5.7
c1-0.9,2.1-1.8,3.2-2.6c1-0.8,1.6-0.5,2.3,0.5c1.4,2.2,1.4,2.5-0.7,4.2c-3,2.5-5.8,4.9-8.7,7.4c-0.1,0.1-0.3,0.4-0.6,0.8
c0.8,0.1,1.3,0.3,1.8,0.4c4.5,0.9,9.2,1.7,13.8,2.5c1.7,0.3,2.6,1.3,3.1,2.9c0.3,0.5,0.4,1.2,0.7,2c-2.2-0.5-3.9-1-5.7-1.4
c-3.6-0.8-7.3-1.4-11-2.1c-6.2-1-12.6-1.6-18.8-1.3c-1.8,0.1-3.6,0.3-5.5,0.5c-0.5,0.1-1.2,0.4-1.7,0.6c-1.6,0.8-3.1,1.7-4.6,2.5
c-0.8,0.4-1.3,0.1-1.7-0.5S0.5,32.4,0,31.9C0,31.7,0,31.4,0,31.3L0,31.3z"/>
<path class="st0" d="M246,0.1c0.8,0.5,1.4,1,2.2,1.6c0.9,0.7,0.9,1.4,0.3,2.2c-1.3,1.7-2.6,3.2-3.9,4.8c-1.2,1.4-2.3,3-3.6,4.4
c-0.5,0.5-0.4,0.9,0.3,1.2c1.2,0.6,1.3,1,0.8,2.3c-0.3,0.8-0.5,1.6-1,2.3c-0.3,0.4-0.6,0.8-1,0.8c-0.9,0.1-1.7,0.1-2.6,0
c-1.3-0.1-2.2,0.4-3.1,1.3c-2.7,2.9-5.5,5.7-8.2,8.7c-0.8,0.9-1.4,0.9-2.2,0.1c-0.1-0.1-0.4-0.3-0.5-0.4c-1.6-0.8-1.8-1.3-0.9-2.7
c1.3-2.1,2.9-3.9,4.4-5.9c0.1-0.1,0.3-0.4,0.6-0.8h-6.6c-1.9-0.1-2.2-0.6-1.8-2.6c0.6-4,3.4-6.2,6.5-8.2c3.2-2.1,6.6-4,10.1-5.9
c2.3-1.2,4.9-2,7.4-3c0.3-0.1,0.5-0.3,0.9-0.5C244.4,0.1,245.2,0.1,246,0.1L246,0.1L246,0.1z M227.6,14c0,0.1,0.1,0.3,0.1,0.5
c1.7-0.1,3.5-0.4,5.2-0.5c0.1,0,0.3-0.1,0.4-0.3c1.7-1.8,3.4-3.8,5.2-5.6c0,0,0-0.1-0.1-0.3C234.6,9.7,231,11.7,227.6,14L227.6,14
L227.6,14z"/>
<path class="st0" d="M286.3,8.3c-0.8,0.6-1.7,1.2-2.3,1.8c-2.7,2.6-5.5,5.2-8.2,7.8c-0.3,0.3-0.5,0.6-0.8,0.9c0,0.1,0.1,0.1,0.1,0.3
c0.4-0.1,0.6-0.3,1-0.4c1.4-0.8,2.9-1.6,4.4-2.3c0.9-0.5,1.6,0,2.3,0.5c0.6,0.5,0.6,1.2,0.4,2.1c-1,4-3.6,7.3-6.2,10.5
c-0.5,0.6-1,0.8-1.8,0.3c-0.3-0.1-0.5-0.4-0.8-0.5c-1.3-0.9-1.3-1.2-0.4-2.5c1.3-2,2.5-3.9,3.8-5.7l-0.3-0.4c-0.5,0.1-1,0.3-1.4,0.6
c-3.1,2.6-6.4,5.2-9.5,7.7c-0.3,0.1-0.4,0.4-0.5,0.5c-1.1,1-2.2,1-3.2-0.3c-0.5-0.6-0.6-1.2,0-2c0.8-1,1.7-2.1,2.5-3.2
c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5,0.3-1,0.6-1.6,0.9c-2.3,1.3-4.8,2.7-7.1,3.9c-2.5,1.2-4.2,0-4.4-2.7s0.9-4.9,2.6-6.9
c2.5-2.9,5.5-4.5,9.3-4.4c0.4,0,0.9,0.4,1.3,0.5c-0.4,0.4-0.6,0.9-1,1c-2.6,0.9-4.8,2.5-6.5,4.5c-0.9,1-1.4,2.2-1.6,3.6
c-0.1,1.2,0.4,1.4,1.4,1c3.8-1.8,7.5-3.4,11.2-5.5c1.6-0.8,2.6-2.5,3.9-3.8c2.7-2.5,5.5-4.9,8.2-7.5c1.2-1.2,2.5-1.4,4-1.2
c0.4,0.1,0.9,0.1,1.3,0.1L286.3,8.3L286.3,8.3L286.3,8.3z"/>
<path class="st0" d="M172,19.5c-1.6,0.5-3.4,1.2-5.3,2c0.3,0.4,0.4,0.6,0.6,0.9c1,1.6,0.9,2.9-0.4,4.3c-1.7,1.8-4,2.2-6.2,2.3
c-1.7,0.1-3.4,0-5.1-0.1c-1-0.1-2.1-1.6-1.8-2.6c0.1-0.3,0.4-0.6,0.6-0.8c1.9-1,3.9-2.1,5.9-3c0.4-0.1,0.8-0.4,1-0.5
c-0.8-1.6-1.3-1.7-2.6-0.9c-4,2.3-8.1,4.7-12.1,7c-0.4,0.3-0.9,0.5-1.4,0.6c-2.2,0.8-3.4,0.3-4-2c-0.3-1.2-0.6-1.2-1.6-0.6
c-2.3,1.2-4.7,2.2-7,3.4c-1,0.5-3.4-0.8-3.5-2c0-0.4,0.3-0.8,0.5-1.2c1.4-1.8,2.7-3.5,4.2-5.3c-0.1-0.1-0.1-0.3-0.3-0.3
c-0.4,0.1-0.6,0.3-1,0.4c-4,2.1-7.4,4.7-10.8,7.7c-0.7,0.5-1.2,1.4-2.2,0.5c-1.4-1.4-1.7-2.2-0.8-3.4c1.7-1.8,3.4-3.6,5.1-5.5
c0.3-0.3,0.7-0.6,0.9-0.9c0-0.4,0-0.5,0-0.6c-1.3,0.9-2.6,1.7-3.9,2.6c-4,2.6-7.9,5.3-11.9,7.9c-1.7,1.2-2.3,1-3.8-0.4
c-0.9-0.9-1-1.4-0.1-2.3c1.9-2.1,3.9-4.2,5.8-6.2c0.3-0.3,0.4-0.5,0.5-0.9c-1,0.6-2.2,1.3-3.2,2c-4.2,2.5-8.3,5.1-12.5,7.4
c-1.9,1.2-3.6,0.5-4.3-1.6c-0.1-0.5,0.1-1.4,0.5-1.8c2.6-2.9,5.3-5.6,7.9-8.3c0.8-0.8,1.6-1.6,2.3-2.3c1.2-1,1.7-0.9,2.5,0.4
c1,1.5,0.8,2.8-0.5,3.9c-1.2,1-2.2,2.1-3.4,3.1c-0.3,0.3-0.4,0.5-0.5,1c4.6-2.5,9.1-4.9,13.5-7.3c0-0.1-0.1-0.3-0.1-0.4
c-0.8,0.1-1.4,0.3-2.2,0.4c-1.2,0.1-2.6-1.6-2.1-2.6c0.1-0.3,0.7-0.5,1-0.6c2.7-0.5,5.3-0.9,8.1-1.3c0.9-0.1,1.6-0.5,2.2-1.2
c1.2-1.2,2.3-2.2,3.5-3.4c0.7-0.7,1.3-0.4,1.7,0.3s0.9,1.3,1,2c0.3,0.9,0.8,1,1.6,0.9c3.9-0.3,7.7-0.6,11.6-0.8
c4.6-0.1,9,0,13.5,0.1c1.7,0,3.2,1.6,3.6,3.2h-1.7c-6.4,0-12.9-0.3-19.2,0c-4,0.1-7.9,0.6-11.8,1.2c-0.8,0.1-1.7,0.5-2.2,1
c-3,2.9-6,5.7-8.8,8.6c-0.1,0.1-0.1,0.3-0.1,0.5c1.7-0.9,3.4-2,5.1-2.9c4-2.2,7.9-4.6,11.9-6.7c1.2-0.6,1.7-0.5,2.9,0.9
c0.8,0.9,0.3,1.6-0.3,2.2c-0.1,0.1-0.3,0.4-0.3,0.8c0.6-0.3,1.3-0.6,1.9-0.9c0.8-0.4,1.7-0.6,2.5-1c0.9-0.4,1.7-0.1,2.3,0.5
c0.8,0.8,0.6,1.6,0.4,2.3c-0.6,1.4-1.4,2.7-2.1,4.2c-0.1,0.3-0.3,0.5-0.4,1c1.4-0.4,2.5-0.6,3.5-1c0.4-0.1,0.5-0.8,0.8-1.2
c1.6-3.1,3.6-5.6,6.9-7c2.1-0.9,4.3-1,6.5-0.3c1.2,0.4,1.9,1.2,2.2,2.3c0.3,1.2-0.5,2-1.4,2.5c-2.6,1.2-5.2,2.2-7.8,3.4
c-0.5,0.3-1.2,0.4-1.7,0.6c-0.3,0.3-0.3,0.9-0.5,1.3c0.4,0.1,0.8,0.3,1,0.1c3.1-1.4,6.1-2.9,9.1-4.3c0.9-0.4,1.8-0.9,2.7-1.4
c0.3-0.1,0.6-0.5,0.6-0.8c0.1-3,0.6-3.8,3.6-4.4c2.3-0.5,4.8-0.5,7,0.4c0.4,0.1,0.9,0.6,1,1.2c0.4,1.2,0,1.7-1.3,1.7
c-1.4,0-3-0.1-4.4-0.1c-0.8,0-1.6,0-1.6,1.2c0.1,0.9,1.3,2.1,1.9,1.8c2.2-0.6,4.4-1.4,6.6-2.2c0.1-0.1,0.4-0.3,0.4-0.5
c1.3-3,3.9-3.4,6.8-3.4c1.7,0,3.2,0.3,4.9,0.5c0.9,0.1,1.7,1.6,1.3,2.3c0,0.1-0.3,0.4-0.4,0.4c-1.4,0.1-2.7,1-4.4,0
c-0.6-0.5-1.9-0.1-2.9,0c-0.8,0.3-0.6,1-0.1,1.4c0.5,0.6,1.3,1,1.9,1.6c1.2,1,2.3,2.1,2.5,3.8c0.1,1-0.3,2-1.2,2.5
c-2.5,1.7-5.3,2.2-8.2,2.1c-1.3-0.1-3.1-1.7-3.8-3.1c0.5,0,0.9,0,1.3-0.1c1.8-0.3,3.6-0.3,5.3-0.6c1.4-0.4,1.6-1.3,0.4-2.3
C174.8,21.7,173.5,20.8,172,19.5C172,19.5,172,19.5,172,19.5z M146.9,20.9c2.3-0.6,4.6-1.6,6.5-3c0.3-0.3,0.4-0.6,0.6-1
c-0.4-0.1-0.9-0.4-1.3-0.4C150.1,17.1,148.3,18.8,146.9,20.9L146.9,20.9L146.9,20.9z M157.2,26.5l0.1,0.4c1.2-0.3,2.5-0.4,3.6-0.6
c0.8-0.3,1.6-0.8,2.3-1.3c0.3-0.1,0.1-0.8,0.3-1c-0.4,0-0.8-0.1-1,0C160.7,24.7,158.8,25.6,157.2,26.5
C157.2,26.5,157.2,26.5,157.2,26.5z"/>
<path class="st0" d="M196.9,23.9h9.9c0.3,0,0.5,0.1,0.8,0.3c-0.1,0.3-0.1,0.6-0.3,0.9c-0.1,0.4-0.5,0.6-0.6,0.9
c-0.8,2.6-2.7,3.6-5.2,3.6c-4.9,0.1-10,0.3-14.9,0.4c-0.9,0-1.2-0.5-1.3-1.3c-0.3-2.3,0.6-4,2.6-5.2c5.3-3.2,10.6-6.5,16-9.6
c2.7-1.7,5.3-3.2,7.9-4.9c0.5-0.4,0.8-1,1.3-1.6c-0.6-0.3-1.3-0.7-1.9-0.5c-3.6,0-7,1-10.4,2.3c-1.4,0.5-2.9,1-4.2,1.6
c-0.5-2.1,0.4-4.4,2.1-5.6c3.1-2.1,6.6-3.4,10.4-4c2.2-0.4,4.3-0.5,6.5,0.4c1.7,0.7,2.7,1.8,3,3.8c0.5,2.9-0.3,5.2-2.6,6.9
c-2.5,1.8-5.1,3.6-7.8,5.3c-3.4,2.1-6.8,3.9-10.3,5.9C197.6,23.5,197.2,23.8,196.9,23.9C196.9,23.6,196.9,23.8,196.9,23.9
L196.9,23.9L196.9,23.9z"/>
<path class="st0" d="M53.8,17.7c1.7,0.3,3.1,0.4,4.5,0.5c0.4,0,0.8-0.4,1.2-0.6c2.1-1.9,4.3-3.5,7-4.2c3.2-0.9,4.9,0.6,6.1,3.5
c0.7,1.7-0.3,2.9-2.2,3.5c-2.6,0.8-5.3,1.2-8,1.2c-1.8,0-3,0.9-3.6,3.1c2.9-0.8,5.5-1.4,8-2.2c0.1,0.1,0.2,0.2,0.3,0.3
c-0.3,0.4-0.4,0.9-0.7,1.2c-2.2,2.1-4.9,3.5-7.9,4.3c-1.8,0.5-2.9-0.5-3.8-2c-0.8-1.2-0.7-2.5,0.1-3.6c0.4-0.4,0.7-0.9,1-1.6
c-1.7-0.3-3.4-0.5-4.9-0.6c-0.5,0-1,0.5-1.4,0.9c-2.1,2.1-4.2,4.2-6.2,6.2c-0.5,0.5-1,1.3-1.8,0.5c-1-1-1.4-2.1-0.8-3
c1.4-1.7,3-3.4,4.4-5.1c0.3-0.3,0.5-0.6,0.9-0.9c-0.5-0.4-1-0.6-1.4-1s-0.8-0.8-0.9-1.2c-0.3-0.5-0.1-1.2-0.3-1.8
c0.5,0,1.2-0.1,1.6,0.1c2.7,1.4,4.8,0.6,6.9-1.4c2.5-2.5,5.1-4.9,8.3-6.8c2.1-1.2,4.4-1.8,6.9-1.4c1.3,0.3,2.1,1.2,2.6,2.3
s0.3,2.3-0.5,3.3s-1.4,0.9-2.1,0c-0.7-1-1.7-1.3-2.7-0.9c-0.7,0.3-1.3,0.4-2,0.8c-2.6,1.9-5.2,3.9-7.8,5.7
C54.3,17.1,54.1,17.3,53.8,17.7L53.8,17.7L53.8,17.7z M64.8,18.2c0,0.1,0.1,0.3,0.1,0.4c1.4-0.4,2.7-0.6,4.2-1
c0.4-0.1,0.7-0.6,1-0.9c-0.4-0.3-0.9-0.8-1.3-0.8C67,15.8,66,17,64.8,18.2z"/>
<path class="st0" d="M84.3,12.6c-1,0.1-1.9,0.3-3,0.5c-1.3,0.1-2.9-1.2-2.7-2.3c0-0.3,0.5-0.6,0.9-0.8c2.1-0.4,4-0.8,6.1-1
c6-0.6,11.9-1.2,17.9-1.7c2.3-0.3,4.7-0.1,7-0.1c0.9,0,2.5,2.1,1.9,2.9c-0.1,0.4-0.8,0.6-1.2,0.8c-2.3,0.4-4.6,0.6-6.9,0.9
c-0.4,0-1-0.1-1.2-0.4c-0.7-0.8-1.4-0.8-2.2-0.8c-2.5,0.3-4.9,0.4-7.5,0.5c-0.5,0-1,0.3-1.6,0.6c-1.4,1.2-2.9,2.5-4.6,3.9
c0.7,0,0.9,0.1,1.2,0.1c1.6-0.1,3.1-0.1,4.8-0.3c1.4,0,3.1,2.3,2.6,3.6c-0.1,0.3-0.8,0.4-1.2,0.5c-2.9,0.1-5.7,0.3-8.6,0.1
c-2.2-0.1-3.6,0.6-5.1,2.3c-3.8,4.4-7.7,8.8-11.6,13.2c-0.9,1-1.2,0.9-1.7-0.3v-0.1c-1-2-0.8-3.8,0.8-5.5c2.2-2.5,4.3-4.9,6.5-7.4
c0.3-0.3,0.5-0.5,0.5-0.6c-0.4-0.9-0.8-1.7-1.2-2.5c-0.4-0.9,0.1-1.3,0.9-1.4c1.4-0.3,2.9-0.5,4.3-0.8c0.5-0.1,0.9-0.4,1.3-0.6
c1-0.9,2.1-1.9,3.1-2.9C84.4,12.9,84.4,12.7,84.3,12.6L84.3,12.6L84.3,12.6z"/>
<path class="st0" d="M240.6,30.9c-0.8-0.1-1.2-0.1-1.6-0.3c-1.2-0.4-1.4-1-1-2.2c0.9-2.6,4.4-4.9,6.9-4.7c1.6,0.3,2.1,1,1.2,2.3
c-1,1.4-2.1,2.7-3.4,3.9C242,30.6,241.1,30.8,240.6,30.9L240.6,30.9L240.6,30.9z"/>
<path class="st0" d="M43,12.5c-0.3-1.2,0.3-1.7,1.3-1.9c2.6-0.6,5.2-1.4,7.8-2.2c0.5-0.1,1.2-0.4,1.3,0.5c0.4,1.4,0.3,2.1-0.8,2.3
c-2.9,0.9-5.7,1.9-8.6,2.7C42.5,14.4,43.3,13,43,12.5C43,12.5,43,12.5,43,12.5z"/>
<path class="st0" d="M183.5,35.8c0.4,0,0.6,0,0.9,0.1s0.5,0.3,0.6,0.4c0.1,0.1,0.3,0.3,0.4,0.5c0.1,0.1,0.1,0.4,0,0.7
c0,0.4-0.1,0.7-0.4,0.9s-0.5,0.5-0.9,0.8s-0.8,0.4-1.3,0.5s-1,0.1-1.6,0.1h-1c-0.3,0-0.5,0-0.6-0.1c-0.3,0-0.5-0.1-0.6-0.1l2.6-5.3
l1.8-0.1l-0.9,2c0.1,0,0.3-0.1,0.5-0.1S183.4,35.8,183.5,35.8L183.5,35.8L183.5,35.8z M181.7,39c0.3,0,0.5,0,0.8-0.1
s0.5-0.1,0.6-0.4s0.4-0.3,0.5-0.5s0.1-0.4,0.3-0.5c0-0.3,0-0.4-0.1-0.5s-0.4-0.3-0.6-0.3h-0.4c-0.1,0-0.4,0.1-0.5,0.1l-1,2.1h0.4
C181.4,39,181.7,39,181.7,39z"/>
<path class="st0" d="M192.6,35.8c-0.3,0.4-0.5,0.8-0.8,1s-0.5,0.7-0.8,0.9s-0.5,0.5-0.9,0.8c-0.3,0.3-0.6,0.5-0.9,0.8
c-0.3,0.1-0.5,0.4-0.8,0.5s-0.5,0.4-0.8,0.5s-0.6,0.3-0.9,0.4s-0.8,0.1-1.2,0.1H185c-0.1,0-0.4-0.1-0.5-0.1l0.6-0.9
c0.1,0,0.3,0.1,0.4,0.1h0.5c0.3,0,0.5,0,0.8-0.1s0.5-0.3,0.6-0.4c-0.1-0.5-0.3-1-0.4-1.7s-0.1-1.3-0.1-2.1h1.8v2.6
c0.4-0.4,0.8-0.8,1.2-1.2s0.6-0.9,0.9-1.4L192.6,35.8C192.6,35.8,192.6,35.8,192.6,35.8z"/>
<path class="st0" d="M197.6,34.7h1.8l-2.6,5.1H195L197.6,34.7L197.6,34.7z"/>
<path class="st0" d="M201.1,39.7c-0.1-0.4-0.1-0.9-0.3-1.3c0-0.4-0.1-0.9-0.1-1.3s-0.1-0.9-0.1-1.3s0-0.8-0.1-1.2h1.9v3.6
c0.3-0.3,0.5-0.5,0.9-0.8c0.3-0.3,0.6-0.7,0.9-0.9s0.6-0.7,0.9-1s0.6-0.7,0.9-0.9h1.9c-0.4,0.4-0.6,0.8-1.2,1.2
c-0.4,0.4-0.8,0.8-1.3,1.3s-0.9,0.9-1.4,1.3c-0.5,0.5-1,0.9-1.6,1.3H201.1L201.1,39.7z"/>
<path class="st0" d="M211.7,39.7v-1h-3c-0.1,0.1-0.4,0.4-0.5,0.5s-0.4,0.4-0.5,0.5h-1.9c0.5-0.5,1.2-1,1.6-1.6
c0.5-0.5,1-0.9,1.4-1.3s0.9-0.8,1.3-1.2s0.9-0.8,1.3-1h1.7c0.1,0.4,0.1,0.8,0.3,1.2c0.1,0.4,0.1,0.9,0.1,1.3s0.1,0.9,0.1,1.3
s0,0.9,0.1,1.3C213.7,39.7,211.7,39.7,211.7,39.7z M211.6,35.7c-0.4,0.4-0.8,0.7-1,1c-0.4,0.3-0.6,0.7-1,1h2.2v-0.9
C211.7,36.5,211.7,36.1,211.6,35.7L211.6,35.7z"/>
<path class="st0" d="M220.3,39.7c-0.3-0.5-0.5-1.2-0.9-1.7s-0.6-1.2-1.2-1.7l-1.7,3.4h-1.8l2.6-5.1h1.4c0.1,0.1,0.4,0.4,0.5,0.7
s0.4,0.5,0.5,0.8s0.4,0.5,0.5,0.8s0.4,0.5,0.5,0.9l1.6-3.1h1.8l-2.6,5.1C221.6,39.7,220.3,39.7,220.3,39.7z"/>
<path class="st0" d="M229.3,39.9h-1.4c-0.5,0-0.9,0-1.3-0.1l2.5-4.9c0.5,0,0.9-0.1,1.4-0.1h1.3c0.5,0,0.9,0,1.2,0.1s0.6,0.1,0.8,0.3
c0.3,0.1,0.4,0.3,0.4,0.4c0.1,0.1,0.1,0.3,0.1,0.4s0,0.3-0.1,0.4c0,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.3
c-0.1,0.1-0.4,0.1-0.8,0.3c0.4,0.1,0.5,0.3,0.8,0.4c0.1,0.1,0.3,0.4,0.1,0.5c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.3-0.4,0.4-0.8,0.5
s-0.8,0.3-1.3,0.4S230,39.7,229.3,39.9C229.3,39.9,229.3,39.9,229.3,39.9z M229.4,37.5l-0.6,1.3h1.7c0.1,0,0.4,0,0.6-0.1
c0.1,0,0.4-0.1,0.5-0.3c0.1-0.1,0.3-0.3,0.3-0.4v-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.3-0.1-0.4-0.1h-0.5H229.4z M229.8,36.6h1.2
c0.5,0,0.8,0,1-0.1s0.4-0.3,0.4-0.5c0-0.1,0-0.2-0.1-0.3s-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.4-0.1h-1.3L229.8,36.6L229.8,36.6
L229.8,36.6z"/>
<path class="st0" d="M241.5,37.9c-0.4,0.7-0.8,1.2-1.4,1.6s-1.4,0.5-2.5,0.5c-0.5,0-0.9,0-1.3-0.1s-0.6-0.1-0.8-0.3
c-0.3-0.1-0.4-0.3-0.4-0.5c-0.1-0.1-0.1-0.4-0.1-0.7s0.1-0.5,0.3-0.8l1.4-2.9h1.8l-1.6,3c-0.1,0.1-0.1,0.4-0.1,0.5v0.3
c0,0.1,0.1,0.1,0.1,0.3s0.3,0.1,0.4,0.1h0.5c0.5,0,0.8-0.1,1-0.3s0.5-0.4,0.6-0.8l1.6-3.1h1.8L241.5,37.9
C241.5,37.9,241.5,37.9,241.5,37.9z"/>
<path class="st0" d="M248.7,39.5c-0.4,0.1-0.6,0.1-1,0.3s-0.9,0.1-1.4,0.1s-0.9,0-1.3-0.1s-0.8-0.3-1-0.4s-0.5-0.4-0.5-0.7
c-0.1-0.3-0.1-0.5-0.1-0.9s0.1-0.8,0.5-1.2c0.3-0.4,0.6-0.8,1-1c0.5-0.3,1-0.5,1.6-0.8c0.6-0.1,1.3-0.3,2.1-0.3c0.4,0,0.8,0,1,0.1
c0.4,0,0.8,0.1,1.2,0.3l-0.8,0.9c-0.3-0.1-0.6-0.1-0.9-0.3h-0.8c-0.4,0-0.9,0-1.2,0.1s-0.6,0.3-0.9,0.5c-0.3,0.1-0.5,0.4-0.6,0.7
s-0.3,0.5-0.3,0.8c0,0.4,0.1,0.8,0.4,0.9s0.6,0.3,1.3,0.3c0.4,0,0.8,0,1-0.1c0.3,0,0.6-0.1,0.9-0.1L248.7,39.5L248.7,39.5z"/>
<path class="st0" d="M257.3,34.7h1.8l-2.6,5.1h-1.8l1-2.2h-2.9l-1,2.2H250l2.6-5.1h1.8l-1,2h2.9L257.3,34.7L257.3,34.7z"/>
<path class="st0" d="M258.5,39.7l2.6-5.1h5.3l-0.5,0.9h-3.5l-0.5,1h3.1l-0.5,0.9h-3.1l-0.6,1.3h3.8l-0.5,0.9H258.5L258.5,39.7z"/>
<path class="st0" d="M270.4,34.5c0.5,0,1,0,1.3,0.1c0.4,0.1,0.6,0.1,0.9,0.3s0.4,0.3,0.5,0.5c0.1,0.1,0.1,0.4,0.1,0.5
s-0.1,0.3-0.1,0.5c-0.1,0.1-0.3,0.3-0.4,0.4s-0.4,0.3-0.6,0.4c-0.3,0.1-0.6,0.3-1,0.3c0.1,0.3,0.4,0.5,0.5,0.9
c0.1,0.4,0.4,0.8,0.5,1.2h-2c-0.1-0.4-0.3-0.7-0.4-1s-0.3-0.7-0.5-0.9h-0.9l-0.9,1.8h-1.8l2.5-5.1c0.4,0,0.8-0.1,1.3-0.1
S270.2,34.5,270.4,34.5L270.4,34.5L270.4,34.5z M270.3,35.6h-0.8l-0.6,1.4h1.6c0.3,0,0.5-0.1,0.6-0.1c0.1-0.1,0.3-0.1,0.4-0.3
s0.1-0.1,0.1-0.3v-0.3c0-0.1-0.1-0.1-0.1-0.3s-0.3-0.1-0.4-0.1C270.7,35.6,270.6,35.6,270.3,35.6C270.3,35.6,270.3,35.6,270.3,35.6z
"/>
</svg>
</template>

